import Vue from 'vue'
import Router from 'vue-router'

import YiBrand from "../components/yiBrand.vue"
import HelloWorld from "../components/HelloWorld.vue"
import YiRed from "../components/yiRed.vue"
import YiPurple from "../components/yiPurple.vue"
import YiBlack from "../components/yiBlack.vue"
import YiYellow from "../components/yiYellow.vue"
import YiWhite from "../components/yiWhite.vue"
import YiDesign from "../components/yiDesign.vue"
import YiStory from "../components/yiStory.vue"
import YiSkin from "../components/yiSkin.vue"
import Connect from "../components/connect.vue"


Vue.use(Router)
export default new Router({
	routes:[{
		path:'/',
		name:YiBrand,
		component:YiBrand
	},
	{
		path:'/HelloWorld',
		name:HelloWorld,
		component:HelloWorld
	},
	{
		path:'/yiRed',
		name:YiRed,
		component:YiRed
	},
	{
		path:'/yiPurple',
		name:YiPurple,
		component:YiPurple
	},
	{
		path:'/yiBlack',
		name:YiBlack,
		component:YiBlack
	},
	{
		path:'/yiYellow',
		name:YiYellow,
		component:YiYellow
	},
	{
		path:'/yiWhite',
		name:YiWhite,
		component:YiWhite
	},
	{
		path:'/yiDesign',
		name:YiDesign,
		component:YiDesign
	},
	{
		path:'/yiStory',
		name:YiStory,
		component:YiStory
	},
	{
		path:'/yiSkin',
		name:YiSkin,
		component:YiSkin
	},{
		path:'/connect',
		name:Connect,
		component:Connect
	}]
})
