<template>
	<div class="yiContainer">
		<!-- 导航 -->
		<div class="section">
			
			<div class="head">
				<div class="headtitle">
				<div class="left">
					<img @click="drawer = true" src="../assets/yiBrand/search.png" alt="">
					<img src="../assets/yiBrand/Fill.png" alt="">
				</div>
				<div class="center" style="cursor: pointer;">
					<router-link to="/"><img src="../assets/yiBrand/logo.png" alt=""></router-link>
				</div>
				<div class="right">
					<router-link to="/connect" style="text-decoration: none;color: #000;">有什么可以帮您？</router-link>
				</div>
			</div>
			</div>
			<el-drawer title="YIQINGTANG一清堂" :visible.sync="drawer" direction="ltr" style="width: 500px;font-size: 20px;"
			size="30%">
			<el-col :span="12">


				<el-menu :router="true" default-active="1" class="el-menu-vertical-demo" @open="handleOpen"
					@close="handleClose">
					<el-submenu index="1">
						<template slot="title">
							
							<span style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">产品</span>
						</template>
						<el-menu-item index="/yiYellow" style="font-family: PingFangRegular;font-size: 14px;">
							倾城秘颜眼部系列</el-menu-item>
						<el-menu-item index="/yiPurple" style="font-family: PingFangRegular;font-size: 14px;">
							嫱颜玉润精粹系列</el-menu-item>
						<el-menu-item index="/yiWhite" style="font-family: PingFangRegular;font-size: 14px;">
							美蝉光感蕴能系列</el-menu-item>
						<el-menu-item index="/yiRed" style="font-family: PingFangRegular;;font-size: 14px;">
							玉奴清滢平衡系列</el-menu-item>
						<el-menu-item index="/yiBlack" style="font-family: PingFangRegular;;font-size: 14px;">
							丽姬凝润冰肌系列</el-menu-item>
					</el-submenu>
					
					<el-menu-item index="/yiStory">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">故事</span>
					</el-menu-item>
					<el-menu-item index="/yiSkin">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">护肤</span>
					</el-menu-item>
					<el-menu-item index="/yiDesign">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">设计</span>
					</el-menu-item>
				</el-menu>
			</el-col>


		</el-drawer>


			<div class="topImg">
				<!-- <d-player ref="player" :options="options"></d-player> -->
				<!-- <div class="login-container" v-show="isvideo" v-html="videoDetails"></div> -->
				<div class="videoArea">
					<video src="https://yuyange-prod.oss-cn-hangzhou.aliyuncs.com/miniapp/2022-09-07_15-47-03_10595.mp4" width="1300px" height="534px" class="video_show"
						controls ref="myVideo" preload="auto" autoplay></video>
					<!-- <video src="https://yuyange-prod.oss-cn-hangzhou.aliyuncs.com/miniapp/2022-09-07_15-47-03_10595.mp4" width="1300px" height="534px" class="video_show"
						:controls="controls" ref="myVideo" preload="auto"
						poster="../assets/yiBrand/skin/topimg.png"></video> -->
				</div>
				<!-- <img src="../assets/yiBrand/design/topimg.png" alt=""> -->
				<!-- <div class="open" v-if="ifopen" @click="open()">
					<img src="../assets/yiBrand/design/open.png" alt="">
				</div> -->
			</div>
		</div>


		<div style="background:#fff;">
		<!-- 产品 故事 护肤 -->
		<div class="product">
			<div class="top_text">
				<el-dropdown>
					<span class="text_product">产品</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<router-link to="/yiYellow" style="text-decoration: none;color: #000;">倾城秘颜眼部系列
							</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiPurple" style="text-decoration: none;color: #000;">嫱颜玉润精粹系列
							</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiWhite" style="text-decoration: none;color: #000;">美蝉光感蕴能系列</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiRed" style="text-decoration: none;color: #000;">玉奴清滢平衡系列</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiBlack" style="text-decoration: none;color: #000;">丽姬凝润冰肌系列</router-link>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<span class="text_story">
					<router-link to="/yiStory" style="text-decoration: none;color: #000;">故事</router-link>
				</span>
				<span class="text_skin">
					<router-link to="/yiSkin" style="text-decoration: none;color: #000;">护肤</router-link>
				</span>
				<span class="text_design">
					<router-link to="/yiDesign" style="text-decoration: none;color: #000;">设计</router-link>
				</span>
			</div>
		</div>


		<!-- 品牌文化 -->
		<div class="customer">
			<img src="../assets/yiBrand/skin/bg1.png" alt="">
		</div>
		<!-- <div class="customer con">
			<div class="content">
				<div class="top">
					<img src="../assets/yiBrand/skin/custom-title.png" alt="">
				</div>
				<div class="detail">
					<img src="../assets/yiBrand/skin/detail-title.png" alt="">
					肌肤结构功能稳定是，肌肤健康的原点，一清堂以修护肌肤结构功能为护肤第一步。
					<br />
					<br />
					保护屏障健康是尤为重要的，基础护肤法则。一清堂提倡长期修护屏障，以保证肌肤系统循环平衡。
					<br />
					<br />
					肌肤是个精妙系统会自我调节与循环,一清堂主张无惧年龄,养护肌肤的正常更替。
					<br />
					<br />
					调：肌肤平衡之道，遵循系统性原则“修-养-抗”三合一 融汇成”调“。
					<br />
					<br />
					一清堂主张“日护+院护+量肤定制’的全方位护肤方式，帮助东方女性达成水油平衡阴阳平衡的健康状态。
				</div>
			</div>
		</div> -->


		<div class="formulation">
			<!-- <div class="con"> -->
			<div class="img">
				<img src="../assets/yiBrand/skin/bigimg.png" alt="">

				<div class="title">
					<div class="big">配方•修合有道</div>
					<div class="small">一清堂深研东方文化，解密东方驻颜智慧，经过十余载科学研发与实验，以东方驻颜智慧为雏形，结合现代人生活方式与肌肤
						问题，引入“修+养+抗=调”的护肤理念，凝练成奢品级系列养肤品，更贴合现代东方肌肤。</div>
				</div>
				<div class="culture">
					<div class="big">修护•驻美有方</div>
					<div class="small">一清堂深谙东方养肤之道，坚持以肌肤结构功能稳定为修护肌肤之根本，在东方智慧“修+养+抗=调”护肤理念框架下，以日
						护+院护+量肤定制的产品组合，多维度，全方面解决肌肤问题，为肌肤带来更奢宠的体验。</div>
				</div>
				<div class="culture">
					<div class="big">平衡•东方气韵</div>
					<div class="small">平衡是东方独有的智慧，水油有衡，阴阳有衡，肌肤有衡。一清堂以驻美配方，调衡肌肤水油，平衡肌肤结构功能稳定。我们
						希望通过平衡养肤之道，东方智慧，改变的不止肌肤，更是让每一个用户在奢享曼妙护肤体验时，身、心、灵亦能归于平衡，重
						现自然美，平衡美，活力美。</div>
				</div>
				<div class="EN_content">
					Formulation: Follow moral cultivation.YQT decodes the ancient oriental wisdom of skincare. After
					over ten years of scientific research and experiment, YQT introduces the skin-care concept of
					“repairing+nurturing+resistance-boosting=recuperating” . The concept is based on ancient oriental
					formula of skincare. It creates luxury products of skincare.Repairing and Care: Pursue natural in
					agerasia.As a master in skincare industry, YQT adheres to the principle of repairing skin by 
					maintaining stable skin structure and functions and offering a holistic beauty experience with a
					product portfolio“daily care+beauty center care+customization based on skin, under the oriental
					skincare framework of “repairing +nurturing +resistance-boosting =recuperating.Balance: oriental
					flavor.Embodying wisdom unique to the oriental world, balance exists between oil and water, yin and
					yang and the internal structures of the skin itself. Its special skincare formulation keeps the
					balance between water and oil within the skin and stabilizes skin structure and functions. We seek
					to help every user to achieve the balance between body, mind and spirit in the wonderful experience
					of balance-oriented skincare
				</div>
			</div>
			<!-- </div> -->
		</div>



		<!-- 系列推荐 -->
		<div class="recommend">
			<div class="title-wrap">
				<div class="name">系列推荐</div>
				<div class="en">PRODUCT SERIESV</div>
				<div class="line"></div>

			</div>
			<div class="content con">
				<!-- 轮播图 -->
				<div class="prev">
					<img src="../assets/yiBrand/prev.png" @click="arrowClick('prev')" />
				</div>
				<div class="carousel">
					<el-carousel arrow="never" height="510px" :autoplay="true" indicator-position="none" ref="cardShow">
						<el-carousel-item v-for="(item, index) in datalist" :key="index">
							<div class="box">
								<div class="left">
									<div class="cover" @click="goPage(item.color1)">
										<img :src="item.img1" alt="">
									</div>
									<div class="name">{{ item.name1 }}</div>
									<div class="more">
										<span @click="goPage(item.color1)">查看更多</span>
									</div>
								</div>
								<div class="right">
									<div class="cover" @click="goPage(item.color2)">
										<img :src="item.img2" alt="">
									</div>
									<div class="name">{{ item.name2 }}</div>
									<div class="more">
										<span @click="goPage(item.color2)">查看更多</span>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="next">
					<img src="../assets/yiBrand/next.png" @click="arrowClick('next')" />
				</div>

			</div>
		</div>


		<!-- 有什么可以帮您？ -->
		<div class="help">
			<div class="title-wrap con">
				<div class="name">有什么可以帮您的？</div>
			</div>
			<div class="content con">
				<div class="left">
					<img src="../assets/yiBrand/phone.png" alt="">
					<div style="cursor: pointer;">400 853 1616</div>
				</div>
				<div class="right">
					<img src="../assets/yiBrand/email.png" alt="">
					<div style="cursor: pointer;">
						<router-link to="/connect" style="text-decoration: none;color: #000;">联系我们</router-link>
					</div>
				</div>
				
			</div>

		</div>

		<div class="section pageBox6">
			<!-- 底部 -->
			<div class="footerBox con">
				<div class="footerBoxHeader">
					<div class="footerBoxHeaderCenter">
						<img class="codeImg" src="../assets/yiBrand/yiqingtang.png" alt="">
						<span class="codeText">官方微信公众号</span>
					</div>
				</div>

				<div class="footerInfoBox">
					FOLLOW MORAL CULTIVATION<br />
					PURSUE NATURAL IN HEALTH PRESERVATION<br />
				</div>

				<div class="footerBoxBottom">
					<div class="footerLeft">
						Copyright © 2023
						<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备20018890号-4</a>
						<br/>
						<img height="16px" src="../assets/plice.png"/>&nbsp;<a target="_blank"  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33052102000853">浙公网安备 33052102000853号</a>
					</div>
					<div class="footerCenter">
						修有道 养有方
					</div>
					<div class="footerRight">
						<img src="../assets/yiBrand/weibo.png" alt="">
						<img src="../assets/yiBrand/weixin.png" alt="">
					</div>
				</div>
			</div>
			<div class="bottom" style="height:180px;background:linear-gradient(to top, #9D92BF, #ECEAF2, #fff);">
				</div>
		</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			ifopen: true,
			controls: false,
			drawer: false,
			datalist: [{
				img1: require("../assets/yiBrand/site-1.png"),
				name1: "倾城秘颜眼部系列",
				color1: "yellow",
				img2: require("../assets/yiBrand/site-2.png"),
				name2: "嫱颜玉润精粹系列",
				color2: "purple"
			},
			{
				img1: require("../assets/yiBrand/site-3.png"),
				name1: "美蝉光感蕴能系列",
				color1: "white",
				img2: require("../assets/yiBrand/site-4.png"),
				name2: "玉奴清滢平衡系列",
				color2: "red"
			},
			{
				img1: require("../assets/yiBrand/site-5.png"),
				name1: "丽姬凝润冰肌系列",
				color1: "black",
				img2: require("../assets/yiBrand/site-1.png"),
				name2: "倾城秘颜眼部系列",
				color2: "yellow"
			},

			],
		};
	},

	methods: {
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		open() {
			this.ifopen = false
			this.controls = true
			this.$refs.myVideo.play();
		},
		// createVideo(){
		// 	let options = {
		// 		//自动播放属性,muted:静音播放
		// 	    autoplay: false,
		// 		//建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
		// 		// preload: "auto",
		// 	    controls: true,
		// 		playbackRates: [0.5, 1, 1.5, 2, 3],// 倍速播放
		// 	    sources: [
		// 	        {
		// 	            src: "http://www.lasur.com/media/beauty/video/2020/12/10/strength2.m3u8",
		// 	        }
		// 	    ]
		// 	}
		// 	this.player = videojs(this.$refs.videoPlayer, options, function onPlayerReady() {
		//                 console.log('onPlayerReady', this);
		//             })
		//         },

		arrowClick(val) {
			if (val === 'next') {
				this.$refs.cardShow.next()
			} else {
				this.$refs.cardShow.prev()
			}
		},

		goPage(val) {
			switch (val) {
				case 'red':
					this.$router.push({
						path: "/yiRed"
					})
					break;
				case 'purple':
					this.$router.push({
						path: "/yiPurple",
					})
					break;
				case 'black':
					this.$router.push({
						path: "/yiBlack",
					})
					break;
				case 'yellow':
					this.$router.push({
						path: "/yiYellow",
					})
					break;
				case 'white':
					this.$router.push({
						path: "/yiWhite",
					})
					break;
			}
		},
	},
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 300px;
	min-height: 400px;
}

.yiContainer {
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	position: relative;
}

/* pageBox6 */
.pageBox6 {
	margin-top: 100px;
	width: 100%;
	height: 652px;
	/* background-image: url(../assets/yiBrand/bg2.png); */
	/* background-color: red; */
}

/* footer */
.pageBox6 .footerBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 999;
}

.pageBox6 .footerBox .footerBoxHeader {
	margin-top: 115px;
}

.footerBoxHeaderCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.codeImg {
	width: 142px;
}

.codeText {
	width: 222px;
	height: 30px;
	line-height: 30px;
	font-size: 22px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #3F3A60;
	margin-top: 19px;
	text-align: center;
}


.footerInfoBox {
	height: 44px;
	line-height: 22px;
	text-align: center;
	font-size: 14px;
	font-family: PingFangRegular;
	font-weight: 400;
	color: #3F3A60;
	margin-top: 48px;
}


.footerBoxBottom {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	margin-top: 52px;
}

.footerLeft {
	width: 338px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #13005A;
	text-align: center;
}

.footerLeft a {
	text-decoration: none;
	cursor: pointer;
	color: #13005A;
}

/* .footerLeft a:hover {
		color: #fff;
	} */

.footerCenter {
	width: 159px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangRegualr;
	font-weight: 300;
	color: #13005A;
	text-align: center;
	margin-left: 233px;
}

.footerRight {
	margin-left: 369px;
}

.footerRight img {
	width: 24px;
	/* height: 33px; */
	margin-left: 66px;
}

/* 导航 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.section{
	width: 1920px;
		/* background-color: #3F3A60; */
		text-align: center;
}

.head {
	/* height: 96px;
	display: flex;
	flex-direction: row;
	justify-content: space-between; */
	/* align-items: center; */
	width: 1920px;
	margin: 0 auto;
	height: 96px;
	/* align-items: center; */
	position: fixed;
	justify-content: center;
	top: 0;
	right:0;
	bottom:0;
	left:0;

	margin:0 auto;
	/* width: auto; */
	z-index: 999;
	background: #fff;
}

.headtitle{
	width: 1300px;
	margin: 0 auto;
	/* background: #13005A; */
	display: flex;
	flex-direction: row;
	/* margin: 0 auto; */
}

.head .left img:first-child{
	width: 38px;
	/* height: 25px; */
	margin-right: 45px;
	cursor: pointer;
	margin-top: 40px;
}


.head .left img:last-child{
	width: 24px;
}

.head .center img{
	width: 341px;
	height: 30px;
	margin-top: 38px;
	margin-left: 370px;
}

.head .right {
	margin-left: 369px;
	margin-top: 43px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #3F3A60;
	line-height: 20px;
}


/* 首图 */
.topImg {
	width: 100%;
	position: relative;
}

.topImg .videoArea {
	/* padding: 0 310px; */
	width: 1300px;
	height: 534px;
	background-color: #000;
	position: fixed;
	top: 0;
	right:0;
	bottom:0;
	left:0;
	margin:0 auto;
 	z-index: -1;
	max-height: 534px;
	transition-property: all;
	margin-top: 96px;
}

/* .topImg .open {
	position: absolute;
	top: 232px;
	left: 628px;
	cursor: pointer;
}

.topImg .videoArea {
	width: 1314px;
	height: 534px;
} */

.topImg .videoArea .video_show {
	margin: 0 auto;
}



/* 产品 故事 护肤 */
.product {
	margin-top: 664px;
	box-shadow: 0px -20px 50px white;
}

.product .top_text {
	text-align: center;
	/* margin-bottom: 53px; */
}

.product .top_text span {
	font-size: 16px;
	font-family: PingFangLight;
	font-weight: 100;
	color: #000000;
	letter-spacing: 1px;
}

.product .top_text .text_product {
	margin-right: 40px;
	cursor: pointer;
}

.product .top_text .text_story {
	margin-right: 40px;
	cursor: pointer;
}

.product .top_text .text_skin {
	cursor: pointer;
	padding-bottom: 5px;
	border-bottom: 1px solid #979797;
}


.product .top_text .text_design {
	margin-left: 40px;
	cursor: pointer;
}



/* 品牌文化 */
.customer {
	margin-top: 26px;
	width: 100%;
	/* position: relative; */
	text-align: center;
	/* background-image: url(../assets/yiBrand/bg1.png); */
	/* overflow: hidden; */
}

.customer img{
	/* width: 1920px; */
	height: 600px;
}

.customer .content {
	display: flex;
	flex-direction: row;
	/* background-color: #3F3A60; */
	/* justify-content: space-between; */
	/* background-color: #3F3A60; */
}

.customer .content .top{
	/* margin-top: 100px; */
	position: relative;
	left: 52px;
	top: 120px;
}

.customer .content .top img{
	width: 78px;
	height: 423px;
}

.customer .content .detail {
	width: 716px;
	height: 312px;
	margin-top: 176px;
	margin-left: 475px;
}

.customer .content .detail img{
	width: 716px;
	height: 305px;
}


/* 配方 */
.formulation {
	/* margin-top: 152px; */
	padding-top: 179px;
	background: linear-gradient(180deg, #F2DEC0 0%, #ECEAF2 28%, #FCFCFD 100%);
}

.formulation .img {
	width: 1446px;
	margin: 0 auto;
}

.formulation .img img{
	width: 1446px;
	height: 541px;
}

.formulation .title {
	margin-top: 106px;
	margin-left: 130px;
}

.formulation .culture {
	margin-top: 97px;
	margin-left: 130px;
}

.formulation .big {
	/* width: 153px; */
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 33px;
}

.formulation .small {
	margin-top: 19px;
	width: 1186px;
	height: 50px;
	font-size: 18px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #A48D29;
	line-height: 25px;
}

.formulation .EN_content {
	margin-top: 103px;
	margin-left: 130px;
	width: 1188px;
	height: 280px;
	font-size: 18px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #A48D29;
	line-height: 28px;
	word-break: break-all;
	text-align: left;
}





/* 系列推荐 */
.recommend {
	margin-top: 193px;
	height: 685px;
	/* background-color: #A48D29; */
}



.recommend .title-wrap {
	text-align: center;
	/* color: #A48D29; */
	margin-bottom: 20px;
}



.recommend .title-wrap .name {
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 33px;
}


.recommend .title-wrap .en {
	font-family: PingFangEXTRALIGHT;
	font-size: 16px;
	margin-top: 3px;
	height: 22px;
	line-height: 22px;
	letter-spacing: 2px;
	color: #A48D29;
}


.recommend .title-wrap .line {
	width: 34px;
	height: 1px;
	background-color: #A48D29;
	margin: auto;
	margin-top: 10px;
}


.recommend .content {
	position: relative;
}

.recommend .content .prev {
	position: absolute;
	left: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;

}

.recommend .content .prev img{
	width: 40px;
	height: 40px;
}

.recommend .content .next {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;
}

.recommend .content .next img{
	width: 40px;
	height: 40px;
}

.recommend .content .box {
	padding: 0 125px;
	display: flex;
	justify-content: space-between;
}

.recommend .content .cover {
	margin-top: 20px;
	width: 518px;
	height: 332px;
	overflow: hidden;
}

.recommend .content .cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s;
	cursor: pointer;
}

.recommend .content .cover img:hover {
	transform: scale(1.1);
}

.recommend .content .box .name {
	height: 29px;
	font-size: 21px;
	margin-top: 10px;
	text-align: center;
	font-family: PingFangLight;
	font-weight: 300;
	color: #595857;
	line-height: 29px;
}

.recommend .content .box .more {
	margin-top: 17px;
}

.recommend .content .box .more span {
	width: 105px;
	height: 27px;
	font-size: 15px;
	font-family: PingFangEXTRALIGHT;
	line-height: 27px;
	padding: 3px 21px 2px 21px;
	border: 1px solid #c1c1c1;
	cursor: pointer;
	margin: 0px 208px;
}


/* 帮助 */

.help {
	/* margin-top: 160px; */
	padding-bottom: 120px;
	border-bottom: 1px solid #797979;
}

.help .title-wrap {
	text-align: center;
	height: 33px;
	line-height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
}

.help .content {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* background-color: #A48D29; */
	/* align-items: center; */
}


.help .content .right,
.help .content .left {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.help .content .left{
	margin-right: 100px;
}

.help .content .left img {
	width: 28px;
	height: 52px;
	margin-right: 19px;
}

.help .content div {
	font-family: PingFangEXTRALIGHT;
	font-size: 20px;
	color: #666;
	font-weight: 100;
}

.help .content .right img{
	width: 52px;
	height: 32px;
	margin-right: 18px;
}


.con {
	width: 1300px;
	margin: 0 auto;
}
</style>
