<template>
	<div class="yiContainer">
		<div class="section con">
			<div class="head">
				<div class="left" @click="goback" style="cursor: pointer;">
					<img src="../assets/yiBrand/back.png" alt="">
					<span>返回</span>
				</div>
				<div class="center" style="cursor: pointer;">
					<router-link to="/"><img src="../assets/yiBrand/logo.png" alt=""></router-link>
				</div>
				<div class="right">
					有什么可以帮您？
				</div>
			</div>
		</div>


		<div class="top-title con">
			<div class="big">联系</div>
			<div class="small">我们乐意为您提供帮助。请将相关的详尽信息发送给我们。</div>
		</div>


		<div class="message" style="margin-top: 68px;">
			名字* <input type="text" autocomplete="off" name="name" />
		</div>
		<div class="message" style="margin-top: 58px;">
			联系电话*<input type="tel" autocomplete="off" name="phone" />
		</div>
		<div class="message" style="margin-top: 58px;">
			电子邮件*<input type="email" autocomplete="off" name="email" />
		</div>
		<div class="message" style="margin-top: 58px;">
			国家地区<br />其他国家/地区<input type="text" autocomplete="off" name="phone" />
		</div>


		<div class="choice">
			<div class="title">主题*</div>
			<el-checkbox v-model="checked1" style="margin-top: 24px;">&nbsp;&nbsp;一般查询</el-checkbox><br />
			<el-checkbox v-model="checked2" style="margin-top: 24px;">&nbsp;&nbsp;招商代理</el-checkbox>
		</div>

		<div class="remarks">
			<div>备注</div>
			<textarea></textarea>
			<div style="margin-top: 37px;">标有*的字段为必填项</div>
			<div style="margin-top: 25px;">点击“发送”，我们将在后台接收到您的消息</div>
		</div>

		<div class="send">
			<button class="black">发送</button>
			<!-- <button class="yellow">发送</button> -->
		</div>



		<div class="section pageBox6">

			<!-- 底部 -->
			<div class="footerBox con">
				<div class="footerBoxHeader">
					<div class="footerBoxHeaderCenter">
						<img class="codeImg" src="../assets/yiBrand/yiqingtang.png" alt="">
						<span class="codeText">官方微信公众号</span>
					</div>
				</div>

				<div class="footerInfoBox">
					FOLLOW MORAL CULTIVATION<br />
					PURSUE NATURAL IN HEALTH PRESERVATION<br />
				</div>

				<div class="footerBoxBottom">
					<div class="footerLeft">
						Copyright © 2023
						<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备20018890号-4</a>
						<br/>
						<img height="16px" src="../assets/plice.png"/>&nbsp;<a target="_blank"  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33052102000853">浙公网安备 33052102000853号</a>
					</div>
					<div class="footerCenter">
						修有道 养有方
					</div>
					<div class="footerRight">
						<img src="../assets/yiBrand/weibo.png" alt="">
						<img src="../assets/yiBrand/weixin.png" alt="">
					</div>
				</div>
			</div>
			<div class="bottom" style="height:180px;background:linear-gradient(to top, #9D92BF, #ECEAF2, #FCFCFD);">
				</div>

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			checked1: true,
			checked2: false
		};
	},
	methods: {
		goback() {
			this.$router.back()
		}
	}

};
</script>

<style scoped>
.yiContainer {
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	position: relative;
}


/* pageBox6 */
.pageBox6 {
	margin-top: 100px;
	width: 100%;
	height: 652px;
	/* background-image: url(../assets/yiBrand/bg2.png); */
	/* background-color: red; */
	border-top: 1px solid #797979;
	;
}

/* footer */
.pageBox6 .footerBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 999;
}

.pageBox6 .footerBox .footerBoxHeader {
	margin-top: 115px;
}

.footerBoxHeaderCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.codeImg {
	width: 142px;
}

.codeText {
	width: 222px;
	height: 30px;
	line-height: 30px;
	font-size: 22px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #3F3A60;
	margin-top: 19px;
	text-align: center;
}


.footerInfoBox {
	height: 44px;
	line-height: 22px;
	text-align: center;
	font-size: 14px;
	font-family: PingFangRegular;
	font-weight: 400;
	color: #3F3A60;
	margin-top: 48px;
}


.footerBoxBottom {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	margin-top: 52px;
}

.footerLeft {
	width: 338px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #13005A;
	text-align: center;
}

.footerLeft a {
	text-decoration: none;
	cursor: pointer;
	color: #13005A;
}

/* .footerLeft a:hover {
		color: #fff;
	} */

.footerCenter {
	width: 159px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangRegualr;
	font-weight: 300;
	color: #13005A;
	text-align: center;
	margin-left: 233px;
}

.footerRight {
	margin-left: 369px;
}

.footerRight img {
	width: 24px;
	/* height: 33px; */
	margin-left: 66px;
}


/* 导航 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.head {
	height: 96px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.head .left {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.head .left span {
	width: 28px;
	height: 20px;
	font-size: 14px;
	font-family: SourceHanSerifSC-Regular, SourceHanSerifSC;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.head .center img{
	width: 409px;
	height: 36px;
}

.head .right {
	height: 20px;
	font-size: 14px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #3F3A60;
	line-height: 20px;
}

.top-title {
	margin-top: 80px;
	/* background-color: #3F3A60; */
}

.top-title .big {
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #333333;
	line-height: 33px;
	text-align: center;
}

.top-title .small {
	margin-top: 17px;
	height: 23px;
	font-size: 16px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 400;
	color: #333333;
	line-height: 23px;
	text-align: center;
}

.message {
	width: 820px;
	font-size: 14px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #333333;
	margin: 0 auto;
	padding-bottom: 14px;
	border-bottom: 1px solid #979797;
}

.message input {
	background-color: transparent;
	width: 80%;
	color: #000;
	border: none;
	/* background-color: #000; */
	text-indent: 10px;
	font-size: 14px;
	/* 字间距 */
	letter-spacing: 2px;
}

input:focus {
	color: #000;
	outline: none;
	transition: 0.5s;
}

.choice {
	width: 820px;
	font-size: 14px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #333333;
	margin: 0 auto;
	margin-top: 54px;
}

.remarks {
	width: 820px;
	font-size: 14px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #333333;
	margin: 0 auto;
	margin-top: 41px;
}

.remarks textarea {
	margin-top: 10px;
	width: 812px;
	height: 252px;
}

/* .choice input{
		margin-top: 24px;
	} */

.send {
	width: 823px;
	margin: 0 auto;
	margin-top: 24px;
}


.send .black {
	width: 823px;
	height: 44px;

	background: #575859;
	border: 0;
	font-size: 14px;
	font-family: SourceHanSerifSC-Regular, SourceHanSerifSC;
	font-weight: 400;
	color: #FFFFFF;
}

.send .black:hover {
	background-color: #A48D29;
}

/* .send .yellow{
		margin-top: 19px;
		width: 823px;
		height: 44px;
		background: #A48D29;
		border: 0;
		font-size: 14px;
		font-family: SourceHanSerifSC-Regular, SourceHanSerifSC;
		font-weight: 400;
		color: #FFFFFF;
	} */



.con {
	width: 1300px;
	margin: 0 auto;
}
</style>
