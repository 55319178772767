<template>
	<div class="yiContainer">
		<!-- 导航 -->
		<div class="section">
			<div class="head">
				<div class="headtitle">
				<div class="left">
					<img @click="drawer = true" src="../assets/yiBrand/search.png" alt="">
					<img src="../assets/yiBrand/Fill.png" alt="">
				</div>
				<div class="center" style="cursor: pointer;">
					<router-link to="/"><img src="../assets/yiBrand/logo.png" alt=""></router-link>
				</div>
				<div class="right">
					<router-link to="/connect" style="text-decoration: none;color: #000;">有什么可以帮您？</router-link>
				</div>
			</div>
			</div>

			<div class="topImg">
				<!-- <d-player ref="player" :options="options"></d-player> -->
				<!-- <div class="login-container" v-show="isvideo" v-html="videoDetails"></div> -->
				<div class="videoArea">
					<video src="https://yuyange-prod.oss-cn-hangzhou.aliyuncs.com/miniapp/2022-09-07_15-47-03_10595.mp4" id="videoPlayer"  width="1300px" height="534px" class="video_show"
						controls ref="myVideo" preload="auto" autoplay></video>
					<!-- <video src="https://yuyange-prod.oss-cn-hangzhou.aliyuncs.com/miniapp/2022-09-07_15-47-03_10595.mp4" id="videoPlayer"  width="1300px" height="534px" class="video_show"
						:controls="controls" ref="myVideo" preload="auto" autoplay></video> -->
						<!-- poster="../assets/yiBrand/story/topimg.png" -->
				</div>
				<!-- <img src="../assets/yiBrand/design/topimg.png" alt=""> -->
				<!-- <div class="open" v-if="ifopen" @click="open()">
					<img src="../assets/yiBrand/design/open.png" alt="">
				</div> -->
			</div>
		</div>


		<el-drawer title="YIQINGTANG一清堂" :visible.sync="drawer" direction="ltr" style="width: 500px;font-size: 20px;"
			size="30%">
			<el-col :span="12">


				<el-menu :router="true" default-active="1" class="el-menu-vertical-demo" @open="handleOpen"
					@close="handleClose">
					<el-submenu index="1">
						<template slot="title">
							<span style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">产品</span>
						</template>
						<el-menu-item index="/yiYellow" style="font-family: PingFangRegular;font-size: 14px;">
							倾城秘颜眼部系列</el-menu-item>
						<el-menu-item index="/yiPurple" style="font-family: PingFangRegular;font-size: 14px;">
							嫱颜玉润精粹系列</el-menu-item>
						<el-menu-item index="/yiWhite" style="font-family: PingFangRegular;font-size: 14px;">
							美蝉光感蕴能系列</el-menu-item>
						<el-menu-item index="/yiRed" style="font-family: PingFangRegular;;font-size: 14px;">
							玉奴清滢平衡系列</el-menu-item>
						<el-menu-item index="/yiBlack" style="font-family: PingFangRegular;;font-size: 14px;">
							丽姬凝润冰肌系列</el-menu-item>
					</el-submenu>
					
					<el-menu-item index="/yiStory">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">故事</span>
					</el-menu-item>
					<el-menu-item index="/yiSkin">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">护肤</span>
					</el-menu-item>
					<el-menu-item index="/yiDesign">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">设计</span>
					</el-menu-item>
				</el-menu>
			</el-col>


		</el-drawer>


		<div style="background:#fff;">
		<!-- 产品 故事 护肤 -->
		<div class="product">
			<div class="top_text">
				<el-dropdown>
					<span class="text_product">产品</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<router-link to="/yiYellow" style="text-decoration: none;color: #000;">倾城秘颜眼部系列
							</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiPurple" style="text-decoration: none;color: #000;">嫱颜玉润精粹系列
							</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiWhite" style="text-decoration: none;color: #000;">美蝉光感蕴能系列</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiRed" style="text-decoration: none;color: #000;">玉奴清滢平衡系列</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiBlack" style="text-decoration: none;color: #000;">丽姬凝润冰肌系列</router-link>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<span class="text_story">
					<router-link to="/yiStory" style="text-decoration: none;color: #000;">故事</router-link>
				</span>
				<span class="text_skin">
					<router-link to="/yiSkin" style="text-decoration: none;color: #000;">护肤</router-link>
				</span>
				<span class="text_design">
					<router-link to="/yiDesign" style="text-decoration: none;color: #000;">设计</router-link>
				</span>
			</div>
		</div>


		<!-- 品牌文化 -->
		<div class="customer">
			<img src="../assets/yiBrand/story/bg1.png" alt="">
		</div>
		<!-- <div class="customer con">
			<div class="content">
				<div class="top">
					<img src="../assets/yiBrand/story/titleimg.png" alt="">
				</div>
				
			</div>
			<div class="EN_content">
				<div class="title">BRAND ORIGIN</div>
				<div class="detail">
					When I peeked at the fair ladies at the royal palace, it felt as if the dwelling of immortals came
					into my sight. The unsurpassed beauty of the history can be seen in the beautiful poetry and verse
					in the historical books. What secrets of maintaining eternal youth are hidden in the mystical
					palace?The 5，000-year history of China has seen one inspiration after another. Nature is the true
					law and it moves in cycles. YQT decodes the ancient oriental wisdom of skincare and discovers that
					structure and function repairing serves as the very basis of skincare. After over ten years of
					scientific research and experiments, we worked on the basis of ancient oriental skincare formula and
					extracted the vegetal essence and combine scientific factors to bring a brand-new formula of
					skincare, which is repairing-maintaining-resisting-adjusting. Every bottle of luxury skincare
					product is infused with the wisdom and beauty of the times.YQT extracts the oriental treasure and
					decodes its secret formula for eternal youth.

				</div>
			</div>
		</div> -->


		<div class="slogan">
			<div class="content con">
				<div class="left">
					<div class="title">
						<div class="top-title">SLOGAN</div>
						<div class="top-content">修有道 养有方</div>
					</div>
					<div class="culture">
						<div class="big">品牌定位</div>
						<div class="small">中国修护抗衰高端品牌</div>
					</div>
					<div class="culture">
						<div class="big">品牌使命</div>
						<div class="small">驻颜秘方照耀女性之美</div>
					</div>
					<div class="culture">
						<div class="big">品牌愿景</div>
						<div class="small">让东方宫廷秘方修护全球女性肌肤</div>
					</div>
					<div class="culture">
						<div class="big">品牌愿景</div>
						<div class="small">高端 智慧 平衡</div>
					</div>
				</div>
				<div class="right">
					<div class="title">
						品牌文化
					</div>

					<div class="cover">
						<img src="../assets/yiBrand/story/Image-1.png" alt="">
					</div>


				</div>

			</div>
		</div>



		<!-- 系列推荐 -->
		<div class="recommend">
			<div class="title-wrap">
				<div class="name">系列推荐</div>
				<div class="en">PRODUCT SERIESv</div>
				<div class="line"></div>

			</div>
			<div class="content con">
				<!-- 轮播图 -->
				<div class="prev">
					<img src="../assets/yiBrand/prev.png" @click="arrowClick('prev')" />
				</div>
				<div class="carousel">
					<el-carousel arrow="never" height="510px" :autoplay="true" indicator-position="none" ref="cardShow">
						<el-carousel-item v-for="(item, index) in datalist" :key="index">
							<div class="box">
								<div class="left">
									<div class="cover" @click="goPage(item.color1)">
										<img :src="item.img1" alt="">
									</div>
									<div class="name">{{ item.name1 }}</div>
									<div class="more">
										<span @click="goPage(item.color1)">查看更多</span>
									</div>
								</div>
								<div class="right">
									<div class="cover" @click="goPage(item.color2)">
										<img :src="item.img2" alt="">
									</div>
									<div class="name">{{ item.name2 }}</div>
									<div class="more">
										<span @click="goPage(item.color2)">查看更多</span>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="next">
					<img src="../assets/yiBrand/next.png" @click="arrowClick('next')" />
				</div>

			</div>
		</div>


		<!-- 有什么可以帮您？ -->
		<div class="help">
			<div class="title-wrap con">
				<div class="name">有什么可以帮您的？</div>
			</div>
			<div class="content con">
				<div class="left">
					<img src="../assets/yiBrand/phone.png" alt="">
					<div style="cursor: pointer;">400 853 1616</div>
				</div>
				<div class="right">
					<img src="../assets/yiBrand/email.png" alt="">
					<div style="cursor: pointer;">
						<router-link to="/connect" style="text-decoration: none;color: #000;">联系我们</router-link>
					</div>
				</div>
				
			</div>

		</div>

		<div class="section pageBox6">
			<!-- 底部 -->
			<div class="footerBox con">
				<div class="footerBoxHeader">
					<div class="footerBoxHeaderCenter">
						<img class="codeImg" src="../assets/yiBrand/yiqingtang.png" alt="">
						<span class="codeText">官方微信公众号</span>
					</div>
				</div>

				<div class="footerInfoBox">
					FOLLOW MORAL CULTIVATION<br />
					PURSUE NATURAL IN HEALTH PRESERVATION<br />
				</div>

				<div class="footerBoxBottom">
					<div class="footerLeft">
						Copyright © 2023
						<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备20018890号-4</a>
						<br/>
						<img height="16px" src="../assets/plice.png"/>&nbsp;<a target="_blank"  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33052102000853">浙公网安备 33052102000853号</a>
					</div>
					<div class="footerCenter">
						修有道 养有方
					</div>
					<div class="footerRight">
						<img src="../assets/yiBrand/weibo.png" alt="">
						<img src="../assets/yiBrand/weixin.png" alt="">
					</div>
				</div>
			</div>
			<div class="bottom" style="height:180px;background:linear-gradient(to top, #9D92BF, #ECEAF2, #FCFCFD);">
				</div>
		</div>
</div>
	</div>
</template>

<script>
import Videojs from "video.js";
export default {
	data() {
		return {
			ifopen: true,
			drawer: false,
			controls: false,

			datalist: [{
				img1: require("../assets/yiBrand/site-1.png"),
				name1: "倾城秘颜眼部系列",
				color1: "yellow",
				img2: require("../assets/yiBrand/site-2.png"),
				name2: "嫱颜玉润精粹系列",
				color2: "purple"
			},
			{
				img1: require("../assets/yiBrand/site-3.png"),
				name1: "美蝉光感蕴能系列",
				color1: "white",
				img2: require("../assets/yiBrand/site-4.png"),
				name2: "玉奴清滢平衡系列",
				color2: "red"
			},
			{
				img1: require("../assets/yiBrand/site-5.png"),
				name1: "丽姬凝润冰肌系列",
				color1: "black",
				img2: require("../assets/yiBrand/site-1.png"),
				name2: "倾城秘颜眼部系列",
				color2: "yellow"
			},

			],
		};
	},

	methods: {
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		open() {
			this.ifopen = false
			this.controls = true
			this.$refs.myVideo.play();
		},
		// createVideo(){
		// 	let options = {
		// 		//自动播放属性,muted:静音播放
		// 	    autoplay: false,
		// 		//建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
		// 		// preload: "auto",
		// 	    controls: true,
		// 		playbackRates: [0.5, 1, 1.5, 2, 3],// 倍速播放
		// 	    sources: [
		// 	        {
		// 	            src: "http://www.lasur.com/media/beauty/video/2020/12/10/strength2.m3u8",
		// 	        }
		// 	    ]
		// 	}
		// 	this.player = videojs(this.$refs.videoPlayer, options, function onPlayerReady() {
		//                 console.log('onPlayerReady', this);
		//             })
		//         },

		arrowClick(val) {
			if (val === 'next') {
				this.$refs.cardShow.next()
			} else {
				this.$refs.cardShow.prev()
			}
		},

		goPage(val) {
			switch (val) {
				case 'red':
					this.$router.push({
						path: "/yiRed"
					})
					break;
				case 'purple':
					this.$router.push({
						path: "/yiPurple",
					})
					break;
				case 'black':
					this.$router.push({
						path: "/yiBlack",
					})
					break;
				case 'yellow':
					this.$router.push({
						path: "/yiYellow",
					})
					break;
				case 'white':
					this.$router.push({
						path: "/yiWhite",
					})
					break;
			}
		},
	},
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 300px;
	min-height: 400px;
}

.yiContainer {
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	position: relative;
}

/* pageBox6 */
.pageBox6 {
	margin-top: 100px;
	width: 100%;
	height: 652px;
	/* background-image: url(../assets/yiBrand/bg2.png); */
	/* background-color: red; */
}

/* footer */
.pageBox6 .footerBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 999;
}

.pageBox6 .footerBox .footerBoxHeader {
	margin-top: 115px;
}

.footerBoxHeaderCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.codeImg {
	width: 142px;
}

.codeText {
	width: 222px;
	height: 30px;
	line-height: 30px;
	font-size: 22px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #3F3A60;
	margin-top: 19px;
	text-align: center;
}


.footerInfoBox {
	height: 44px;
	line-height: 22px;
	text-align: center;
	font-size: 16px;
	font-family: PingFangRegular;
	font-weight: 400;
	color: #3F3A60;
	margin-top: 48px;
}


.footerBoxBottom {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	margin-top: 52px;
}

.footerLeft {
	width: 338px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #3F3A60;
	text-align: center;
}

.footerLeft a {
	text-decoration: none;
	cursor: pointer;
	color: #3F3A60;
}

/* .footerLeft a:hover {
		color: #fff;
	} */

.footerCenter {
	width: 159px;
	height: 20px;
	line-height: 20px;
	font-size: 18px;
	font-family: PingFangRegualr;
	font-weight: 300;
	color: #3F3A60;
	text-align: center;
	margin-left: 233px;
}

.footerRight {
	margin-left: 369px;
}

.footerRight img {
	width: 28px;
	/* height: 33px; */
	margin-left: 66px;
}

/* 导航 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.section{
	width: 1920px;
		/* background-color: #3F3A60; */
		text-align: center;
}

.head {
	/* height: 96px;
	display: flex;
	flex-direction: row;
	justify-content: space-between; */
	/* align-items: center; */
	width: 1920px;
	margin: 0 auto;
	height: 96px;
	/* align-items: center; */
	position: fixed;
	justify-content: center;
	top: 0;
	right:0;
	bottom:0;
	left:0;

	margin:0 auto;
	/* width: auto; */
	z-index: 999;
	background: #fff;
}

.headtitle{
	width: 1300px;
	margin: 0 auto;
	/* background: #13005A; */
	display: flex;
	flex-direction: row;
	/* margin: 0 auto; */
}

.head .left img:first-child{
	width: 38px;
	/* height: 25px; */
	margin-right: 45px;
	cursor: pointer;
	margin-top: 40px;
}


.head .left img:last-child{
	width: 24px;
}

.head .center img{
	width: 341px;
	height: 30px;
	margin-top: 38px;
	margin-left: 370px;
}

.head .right {
	margin-left: 369px;
	margin-top: 43px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #3F3A60;
	line-height: 20px;
}


/* 首图 */
.topImg {
	width: 100%;
	/* position: relative; */
	margin-top: 96px;
}

.topImg .videoArea {
	/* padding: 0 310px; */
	width: 1300px;
	height: 534px;
	background-color: #000;
	position: fixed;
	top: 0;
	right:0;
	bottom:0;
	left:0;
	margin:0 auto;
 	z-index: -1;
	max-height: 534px;
	transition-property: all;
	margin-top: 96px;
}

/* .topImg .open {
	position: absolute;
	top: 232px;
	left: 628px;
	cursor: pointer;
} */

/* .topImg .videoArea {
	width: 1303px;
	height: 534px;
} */

.topImg .videoArea .video_show {
	margin: 0 auto;
}



/* 产品 故事 护肤 */
.product {
	margin-top: 664px;
	box-shadow: 0px -20px 50px white;
}

.product .top_text {
	text-align: center;
	/* margin-bottom: 53px; */
}

.product .top_text span {
	font-size: 16px;
	font-family: PingFangLight;
	font-weight: 100;
	color: #000000;
	letter-spacing: 1px;
}

.product .top_text .text_product {
	margin-right: 40px;
	cursor: pointer;
}

.product .top_text .text_story {
	margin-right: 40px;
	cursor: pointer;
	padding-bottom: 5px;
	border-bottom: 1px solid #979797;
}

.product .top_text .text_skin {
	cursor: pointer;
}


.product .top_text .text_design {
	margin-left: 40px;
	cursor: pointer;
}



/* 品牌文化 */
.customer {
	margin-top: 26px;
	width: 100%;
	text-align: center;
	/* position: relative; */
	/* background-image: url(../assets/yiBrand/bg1.png); */
	/* overflow: hidden; */
}

.customer img{
	/* width: 1920px; */
	height: 800px;
}

.customer .content {
	/* display: flex;
	flex-direction: row; */
	/* background-color: #3F3A60; */
	/* justify-content: space-between; */
	/* background-color: #3F3A60; */
}

.customer .content .top {
	margin-top: 138px;
	margin-left: 76px;
}

.customer .content .top img {
	/* margin-top: 100px; */
	width: 1195px;
	height: 379px;
}

/* .customer .content .detail {

	margin-top: 140px;
	margin-left: 560px;
	font-size: 20px;
	color: #A48D29;
	font-family: PingFangRegular;
	font-weight: 100;

	letter-spacing: 1px;
	writing-mode: vertical-rl;

} */

.customer .EN_content {
	width: 1100px;
	margin: 0 auto;
	margin-top: 120px;
}

.customer .EN_content .title {
	/* width: 188px; */
	height: 33px;
	font-size: 24px;
	font-family: PingFangRegular;
	font-weight: 300;
	color: #A48D29;
	line-height: 33px;
}

.customer .EN_content .detail {
	width: 1215px;
	height: 175px;
	margin-top: 26px;
	font-size: 18px;
	font-family: PingFangLight;
	font-weight: 100;
	color: #A48D29;
	line-height: 25px;
	word-break: break-all;
}



/* slogan */
.slogan {
	margin-top: 124px;
	height: 834px;
	background: linear-gradient(to bottom, #F2DEC0, #ECEAF2, #FCFCFD,#fff);
}

.slogan .content {
	height: 668px;
	position: relative;

}

.slogan .content .left {
	position: absolute;
	top: 255px;
	left: 112px;
}

.slogan .content .left .title .top-title {
	width: 86px;
	height: 29px;
	font-size: 21px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 29px;
}

.slogan .content .left .title .top-content {
	margin-top: 2px;
	width: 160px;
	height: 33px;
	font-size: 24px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #A48D29;
	line-height: 33px;
}

.slogan .content .culture {
	margin-top: 55px;
}

.slogan .content .culture .big {
	/* width: 120px; */
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 33px;
}

.slogan .content .culture .small {
	margin-top: 11px;
	height: 25px;
	font-size: 18px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #A48D29;
	line-height: 25px;
}

.slogan .content .right {
	position: absolute;
	top: 158px;
	left: 598px;
	display: flex;
}

.slogan .content .right .title {
	width: 32px;
	height: 136px;
	font-size: 21px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 34px;
}

.slogan .content .right .cover {
	margin-top: 8px;
	margin-left: 84px;
}




/* 系列推荐 */
.recommend {
	margin-top: 193px;
	height: 685px;
	/* background-color: #A48D29; */
}



.recommend .title-wrap {
	text-align: center;
	/* color: #A48D29; */
	margin-bottom: 20px;
}



.recommend .title-wrap .name {
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 33px;
}


.recommend .title-wrap .en {
	font-family: PingFangEXTRALIGHT;
	font-size: 16px;
	margin-top: 3px;
	height: 22px;
	line-height: 22px;
	letter-spacing: 2px;
	color: #A48D29;
}


.recommend .title-wrap .line {
	width: 34px;
	height: 1px;
	background-color: #A48D29;
	margin: auto;
	margin-top: 10px;
}


.recommend .content {
	position: relative;
}

.recommend .content .prev {
	position: absolute;
	left: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;

}

.recommend .content .prev img{
	width: 40px;
	height: 40px;
}

.recommend .content .next {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;
}

.recommend .content .next img{
	width: 40px;
	height: 40px;
}

.recommend .content .box {
	padding: 0 125px;
	display: flex;
	justify-content: space-between;
}

.recommend .content .cover {
	margin-top: 20px;
	width: 518px;
	height: 332px;
	overflow: hidden;
}

.recommend .content .cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s;
	cursor: pointer;
}

.recommend .content .cover img:hover {
	transform: scale(1.1);
}

.recommend .content .box .name {
	height: 29px;
	font-size: 21px;
	margin-top: 10px;
	text-align: center;
	font-family: PingFangLight;
	font-weight: 300;
	color: #595857;
	line-height: 29px;
}

.recommend .content .box .more {
	margin-top: 17px;
}

.recommend .content .box .more span {
	width: 105px;
	height: 27px;
	font-size: 15px;
	font-family: PingFangEXTRALIGHT;
	line-height: 27px;
	padding: 3px 21px 2px 21px;
	border: 1px solid #c1c1c1;
	cursor: pointer;
	margin: 0px 208px;
}


/* 帮助 */

.help {
	/* margin-top: 160px; */
	padding-bottom: 120px;
	border-bottom: 1px solid #797979;
}

.help .title-wrap {
	text-align: center;
	height: 33px;
	line-height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
}

.help .content {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* background-color: #A48D29; */
	/* align-items: center; */
}


.help .content .right,
.help .content .left {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.help .content .left{
	margin-right: 100px;
}

.help .content .left img {
	width: 28px;
	height: 52px;
	margin-right: 19px;
}

.help .content div {
	font-family: PingFangEXTRALIGHT;
	font-size: 20px;
	color: #666;
	font-weight: 100;
}

.help .content .right img{
	width: 52px;
	height: 32px;
	margin-right: 18px;
}


.con {
	width: 1300px;
	margin: 0 auto;
}
</style>
