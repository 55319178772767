<template>
	<div class="yiContainer">
	
		<div class="navigation">
		
			<div class="head">
				<div class="headtitle">
				<div class="left">
					<img @click="drawer = true" src="../assets/yiBrand/search.png" alt="">
					<img src="../assets/yiBrand/Fill.png" alt="">
				</div>
				<div class="center" style="cursor: pointer;">
					<router-link to="/"><img src="../assets/yiBrand/logo.png" alt=""></router-link>
				</div>
				<div class="right">
					<router-link to="/connect" style="text-decoration: none;color: #000;">有什么可以帮您？</router-link>
				</div>
			</div>
		</div>
			<div class="topImg">
				<img src="../assets/yiBrand/topimg.png" alt="">
			</div>
		
		</div>
	
		<el-drawer title="YIQINGTANG一清堂" :visible.sync="drawer" direction="ltr" style="width: 500px;font-size: 20px;"
			size="30%">
			<el-col :span="12">


				<el-menu :router="true" default-active="1" class="el-menu-vertical-demo" @open="handleOpen"
					@close="handleClose">
					<el-submenu index="1">
						<template slot="title">
							
							<span style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">产品</span>
						</template>
						<el-menu-item index="/yiYellow" style="font-family: PingFangRegular;font-size: 14px;">
							倾城秘颜眼部系列</el-menu-item>
						<el-menu-item index="/yiPurple" style="font-family: PingFangRegular;font-size: 14px;">
							嫱颜玉润精粹系列</el-menu-item>
						<el-menu-item index="/yiWhite" style="font-family: PingFangRegular;font-size: 14px;">
							美蝉光感蕴能系列</el-menu-item>
						<el-menu-item index="/yiRed" style="font-family: PingFangRegular;;font-size: 14px;">
							玉奴清滢平衡系列</el-menu-item>
						<el-menu-item index="/yiBlack" style="font-family: PingFangRegular;;font-size: 14px;">
							丽姬凝润冰肌系列</el-menu-item>
					</el-submenu>
					
					<el-menu-item index="/yiStory">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">故事</span>
					</el-menu-item>
					<el-menu-item index="/yiSkin">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">护肤</span>
					</el-menu-item>
					<el-menu-item index="/yiDesign">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">设计</span>
					</el-menu-item>
				</el-menu>
			</el-col>


		</el-drawer>
	
		<div style="background:#fff;">
		<!-- 品牌文化 -->
		<div class="customer ">
			<img src="../assets/yiBrand/bg1.png" alt="">
			<!-- <div class="content con">
				<div class="top">
					<img src="../assets/yiBrand/custom-title.png" alt="">
				</div>
				<div class="detail">
					一清堂深研东方文化<br><br/>
					解密东方古时护肤理念<br><br/>
					以修护肌肤结构功能稳定<br><br/>
					传承智慧为基点<br><br/>
					通过“修+养+抗=调”<br><br/>
					的品牌护肤理念<br/><br/>
					<br /><br/>
					奉上修护肌肤根本的<br /><br/>
					养肌抗衰奢品<br /><br/>
					为女性带来奢享曼妙护肤体验<br /><br/>
					也带来肌肤、身体、<br /><br/>
					心灵三者的平衡与满足<br /><br/>
				</div>
			</div> -->
		</div>


		<!-- 系列推荐 -->
		<div class="recommend">
			<div class="title-wrap">
				<div class="name">系列推荐</div>
				<div class="en">PRODUCT SERIESV</div>
				<div class="line"></div>

			</div>
			<div class="content con">
				<!-- 轮播图 -->

				<div class="prev">
					<img src="../assets/yiBrand/prev.png" @click="arrowClick('prev')" />
				</div>
				<div class="carousel">
					<el-carousel arrow="never" height="510px" :autoplay="true" indicator-position="none" ref="cardShow">
						<el-carousel-item v-for="(item, index) in datalist" :key="index">
							<div class="box">
								<div class="left">
									<div class="cover" @click="goPage(item.color1)">
										<img :src="item.img1" alt="">
									</div>
									<div class="name">{{ item.name1 }}</div>
									<div class="more">
										<span @click="goPage(item.color1)">查看更多</span>
									</div>
								</div>
								<div class="right">
									<div class="cover" @click="goPage(item.color2)">
										<img :src="item.img2" alt="">
									</div>
									<div class="name">{{ item.name2 }}</div>
									<div class="more">
										<span @click="goPage(item.color2)">查看更多</span>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="next">
					<img src="../assets/yiBrand/next.png" @click="arrowClick('next')" />
				</div>

			</div>
		</div>

		<!-- 品牌文化 -->
		<div class="brand con">
			<img class="borderleft" src="../assets/yiBrand/border-1.png" alt="" />
			<div class="brand_title">
				<div class="title-wrap">
					<div class="name">品牌文化</div>
					<div class="en">BRAND CULTURE</div>
					<div class="line"></div>
				</div>
				<div class="content">
					<!-- 品牌理念 -->
					<div class="card">
						<div class="cover">
							<router-link to="/yiStory"><img src="../assets/yiBrand/brand-1.png" alt=""></router-link>
						</div>
						<div class="name">品牌故事</div>
						<div class="more">
							<router-link to="/yiStory" style="text-decoration: none;color: #000;cursor: pointer;">阅读更多
							</router-link>
						</div>
					</div>
					<!-- 品牌VI -->
					<div class="card">
						<div class="cover">
							<router-link to="/yiDesign"><img src="../assets/yiBrand/brand-2.png" alt=""></router-link>
						</div>
						<div class="name">设计理念</div>
						<div class="more">
							<router-link to="/yiDesign" style="text-decoration: none;color: #000;cursor: pointer;">阅读更多
							</router-link>
						</div>
					</div>
					<!-- 护肤哲学 -->
					<div class="card">
						<div class="cover">
							<router-link to="/yiSkin"><img src="../assets/yiBrand/brand-3.png" alt=""></router-link>
						</div>
						<div class="name">护肤哲学</div>
						<div class="more">
							<router-link to="/yiSkin" style="text-decoration: none;color: #000;cursor: pointer;">阅读更多
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<img class="borderright" src="../assets/yiBrand/border-2.png" alt="" />
		</div>


		<!-- 有什么可以帮您？ -->
		<div class="help">
			<div class="title-wrap con">
				<div class="name">有什么可以帮您的？</div>
			</div>
			<div class="content con">
				<div class="left">
					<img src="../assets/yiBrand/phone.png" alt="">
					<div style="cursor: pointer;">400 853 1616</div>
				</div>
				<div class="right">
					<img src="../assets/yiBrand/email.png" alt="">
					<div style="cursor: pointer;">
						<router-link to="/connect" style="text-decoration: none;color: #000;">联系我们</router-link>
					</div>
				</div>
			</div>

		</div>


		<div class="section pageBox6">

			<!-- 底部 -->
			<div class="footerBox con">
				<div class="footerBoxHeader">
					<div class="footerBoxHeaderCenter">
						<img class="codeImg" src="../assets/yiBrand/yiqingtang.png" alt="">
						<span class="codeText">官方微信公众号</span>
					</div>
				</div>

				<div class="footerInfoBox">
					FOLLOW MORAL CULTIVATION<br />
					PURSUE NATURAL IN HEALTH PRESERVATION<br />
				</div>

				<div class="footerBoxBottom">
					<div class="footerLeft">
						Copyright © 2023
						<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备20018890号-4</a>
						<br/>
						<img height="16px" src="../assets/plice.png"/>&nbsp;<a target="_blank"  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33052102000853">浙公网安备 33052102000853号</a>
					</div>
					<div class="footerCenter">
						修有道 养有方
					</div>
					<div class="footerRight">
						<img src="../assets/yiBrand/weibo.png" alt="">
						<img src="../assets/yiBrand/weixin.png" alt="">
					</div>
				</div>
			</div>

			<div class="bottom" style="height:180px;background:linear-gradient(to top, #9D92BF, #ECEAF2, #FCFCFD,#fff);">
				</div>
		</div>
	</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			drawer: false,
			datalist: [{
				img1: require("../assets/yiBrand/site-1.png"),
				name1: "倾城秘颜眼部系列",
				color1: "yellow",
				img2: require("../assets/yiBrand/site-2.png"),
				name2: "嫱颜玉润精粹系列",
				color2: "purple"
			},
			{
				img1: require("../assets/yiBrand/site-3.png"),
				name1: "美蝉光感蕴能系列",
				color1: "white",
				img2: require("../assets/yiBrand/site-4.png"),
				name2: "玉奴清滢平衡系列",
				color2: "red"
			},
			{
				img1: require("../assets/yiBrand/site-5.png"),
				name1: "丽姬凝润冰肌系列",
				color1: "black",
				img2: require("../assets/yiBrand/site-1.png"),
				name2: "倾城秘颜眼部系列",
				color2: "yellow"
			},

			],
		};
	},
	methods: {
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		arrowClick(val) {
			if (val === 'next') {
				this.$refs.cardShow.next()
			} else {
				this.$refs.cardShow.prev()
			}
		},
		goPage(val) {
			switch (val) {
				case 'red':
					this.$router.push({
						path: "/yiRed"
					})
					break;
				case 'purple':
					this.$router.push({
						path: "/yiPurple",
					})
					break;
				case 'black':
					this.$router.push({
						path: "/yiBlack",
					})
					break;
				case 'yellow':
					this.$router.push({
						path: "/yiYellow",
					})
					break;
				case 'white':
					this.$router.push({
						path: "/yiWhite",
					})
					break;
			}

		}
	},
};
</script>

<style scoped>

/* @keyframes left{
		from{transform: translateY(100px);opacity: .2;}
		percent{transform: translateY(-100px);opacity: .2;}
		to{transform: translateY(-100px);opacity: .2;}
	} */

.borderleft{
	width:6px;
	height:459px;
	margin-top: 30px;
	/* animation: right 8s infinite;
	animation-timing-function: value; */
}

	/* @keyframes right{
		0%{transform: translateY(-100px);opacity: .5;}
		25%{transform: translateY(0px);transition-delay: 1s;}
		50%{transform: translateY(100px);opacity: .5;}
		75%{transform: translateY(0px);transition-delay: 1s;}
		100%{transform: translateY(-100px);opacity: .5;}
	} */

.borderright{
	width:6px;
	height:459px;
	margin-top: 30px;
	/* animation: right 8s infinite;
	animation-timing-function: value; */
}	

.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 300px;
	min-height: 400px;
}

.yiContainer {
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	position: relative;

}


/* pageBox6 */
.pageBox6 {
	margin-top: 100px;
	width: 100%;
	height: 652px;
	/* background-image: url(../assets/yiBrand/bg2.png); */
	/* background-color: red; */
}

/* footer */
.pageBox6 .footerBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 999;
}

.pageBox6 .footerBox .footerBoxHeader {
	margin-top: 115px;
}

.footerBoxHeaderCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.codeImg {
	width: 142px;
}

.codeText {
	width: 222px;
	height: 30px;
	line-height: 30px;
	font-size: 22px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #3F3A60;
	margin-top: 19px;
	text-align: center;
}


.footerInfoBox {
	height: 44px;
	line-height: 22px;
	text-align: center;
	font-size: 14px;
	font-family: PingFangRegular;
	font-weight: 400;
	color: #3F3A60;
	margin-top: 48px;
}


.footerBoxBottom {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	margin-top: 52px;
}

.footerLeft {
	width: 338px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #13005A;
	text-align: center;
}

.footerLeft a {
	text-decoration: none;
	cursor: pointer;
	color: #13005A;
}

/* .footerLeft a:hover {
		color: #fff;
	} */

.footerCenter {
	width: 159px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangRegualr;
	font-weight: 300;
	color: #13005A;
	text-align: center;
	margin-left: 233px;
}

.footerRight {
	margin-left: 369px;
}

.footerRight img {
	width: 24px;
	/* height: 33px; */
	margin-left: 66px;
}


/* 导航 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.navigation{
		width: 1920px;
		/* background-color: #3F3A60; */
		text-align: center;
	}

.head {
	width: 1920px;
	margin: 0 auto;
	height: 96px;
	/* align-items: center; */
	position: fixed;
	justify-content: center;
	top: 0;
	right:0;
	bottom:0;
	left:0;

	margin:0 auto;
	/* width: auto; */
	z-index: 999;
	background: #fff;
}

.headtitle{
	width: 1300px;
	margin: 0 auto;
	/* background: #13005A; */
	display: flex;
	flex-direction: row;
	/* margin: 0 auto; */
}

.head .left img:first-child{
	width: 38px;
	/* height: 25px; */
	margin-right: 45px;
	cursor: pointer;
	margin-top: 40px;
	/* margin-left: 305px; */
}


.head .left img:last-child{
	width: 24px;
}

.head .center img{
	width: 341px;
	height: 30px;
	margin-top: 38px;
	margin-left: 370px;
}

.head .right {
	margin-left: 369px;
	margin-top: 43px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #3F3A60;
	line-height: 20px;
}


/* 首图 */
.topImg {
	margin-top: 96px;
	width: 100%;
	position: fixed;
 	background-color: #fff;
 	z-index: -1;
	max-height: 462px;
	transition-property: all;
 
}

.topImg img {
	width: 1303px;
	height: 462px;
	/* padding: 60px 310px; */
}


/* 品牌文化 */
.customer {
	width: 100%;
	height: 513px;
	margin-top: 610px;
	/* position: relative; */
	/* background-image: url(../assets/yiBrand/bg1.png); */
	box-shadow: 0px -40px 50px white;
	/* overflow: hidden; */
	text-align: center;
}

.customer img{
	width: 1920px;
	height: 513px;
}


.customer .content {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	/* background-color: #3F3A60; */
}

.customer .top img {
	/* margin-top: 100px; */
	position: relative;
	left: 150px;
	top: 91px;
	width: 60px;
}

.customer .detail {
	writing-mode: tb-rl;
	margin-top: 80px;
	margin-left: 635px;
	font-size: 14px;
	color: #A48D29;
	letter-spacing: 6px;
	font-family: PingFangRegular;
	/* background-color: #A48D29; */
}

/* 系列推荐 */
.recommend {
	margin-top: 41px;
	height: 685px;
}


.brand .title-wrap,
.recommend .title-wrap {
	text-align: center;
	/* color: #A48D29; */
	margin-bottom: 20px;
}


.brand .title-wrap .name,
.recommend .title-wrap .name {
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 33px;
}


.brand .title-wrap .en,
.recommend .title-wrap .en {
	font-family: PingFangEXTRALIGHT;
	font-size: 16px;
	margin-top: 3px;
	height: 22px;
	line-height: 22px;
	letter-spacing: 2px;
	color: #A48D29;
}


.brand .title-wrap .line,
.recommend .title-wrap .line {
	width: 34px;
	height: 1px;
	background-color: #A48D29;
	margin: auto;
	margin-top: 10px;
}


.recommend .content {
	position: relative;
	/* background-color: #3F3A60; */
}

.recommend .content .prev {
	/* background-color: #3F3A60; */
	position: absolute;
	left: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;
}

.recommend .content .prev img{
	width: 40px;
	height: 40px;
}

.recommend .content .next {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;
}

.recommend .content .next img{
	width: 40px;
	height: 40px;
}

.recommend .content .box {
	padding: 0 125px;
	display: flex;
	justify-content: space-between;
}

.recommend .content .cover {
	margin-top: 20px;
	width: 518px;
	height: 332px;
	overflow: hidden;
}

.recommend .content .cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s;
	cursor: pointer;
}

.recommend .content .cover img:hover {
	transform: scale(1.1);
}

.recommend .content .box .name {
	height: 29px;
	font-size: 21px;
	margin-top: 10px;
	text-align: center;
	font-family: PingFangLight;
	font-weight: 300;
	color: #595857;
	line-height: 29px;
}

.recommend .content .box .more {
	margin-top: 17px;
}

.recommend .content .box .more span {
	/* background-color: #3F3A60; */
	width: 105px;
	height: 27px;
	font-size: 15px;
	font-family: PingFangEXTRALIGHT;
	line-height: 27px;
	padding: 3px 21px 2px 21px;
	border: 1px solid #c1c1c1;
	cursor: pointer;
	margin: 0px 208px;
}


/* 品牌文化 */
.brand {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.brand .content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 1138px;
	margin: auto;
}

.brand .content .card .cover {
	width: 344px;
	height: 345px;
	overflow: hidden;
}

.brand .content .card .cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s;
	cursor: pointer;
}

.brand .content .card .cover img:hover {
	transform: scale(1.1);
}

.brand .content .card .name {
	height: 29px;
	font-size: 21px;
	line-height: 29px;
	font-family: PingFangLight;
	color: #595857;
	font-weight: 300;
	margin-top: 26px;
	text-align: center;
}

.brand .content .card .more {
	width: 66px;
	font-size: 16px;
	height: 22px;
	line-height: 22px;
	font-family: PingFangEXTRALIGHT;
	margin: auto;
	margin-top: 15px;
	color: #595857;
	padding-bottom: 4px;
	border-bottom: 1px solid #979797;
}



/* 有什么可以帮您？？ */
.help {
	margin-top: 160px;
	padding-bottom: 120px;
	border-bottom: 1px solid #797979;
}

.help .title-wrap .name{
	text-align: center;
	height: 33px;
	line-height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
}

.help .content {
	margin-top: 69px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* background-color: #A48D29; */
	/* align-items: center; */
}


.help .content .right,
.help .content .left {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.help .content .left{
	margin-right: 100px;
}

.help .content .left img {
	width: 28px;
	height: 52px;
	margin-right: 19px;
}

.help .content div {
	font-family: PingFangEXTRALIGHT;
	font-size: 20px;
	color: #666;
	font-weight: 100;
}

.help .content .right img{
	width: 52px;
	height: 32px;
	margin-right: 18px;
}

/* .brand .content .card .more::after {
		content: '';
		display: block;
		width: 56px;
		margin: auto;
		height: 1px;
		background-color: #a69e9c;
		margin-top: 2px;
	} */

.con {
	width: 1300px;
	margin: 0 auto;
}
</style>
