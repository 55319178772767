<template>
  <div id="app">
	<router-view/>
  </div>
</template>

<script>

import YiBrand from './components/yiBrand.vue'

export default {
  name: 'app',
  components: {
	YiBrand,

  }
}
</script>

<style>
#app{
		margin-top: 0px;
	}
	
	body,html {
	    width: 100%;
	    height: 100%;
	  }
	  body{
	  margin: 0;
	  padding: 0;
	  list-style: none;
	  }
	  
	  
	.el-tabs__header.is-top{
		display: flex;
    justify-content: center;
	}
	
	.el-tabs__item .is-top{
		padding-left: 2em;
		
	}
	
	.el-tabs__item {
		font-size: 1rem !important;
	}
	
	.el-drawer{
		width:60% !important;
	}
	
	.el-tabs__nav-next, .el-tabs__nav-prev{
		font-size: 30px !important;
	}
</style> 
