<template>
	<div class="yiContainer">
		<!-- 导航 -->
		<div class="section">
			<div class="head">
				<div class="headtitle">
				<div class="left">
					<img @click="drawer = true" src="../assets/yiBrand/search.png" alt="">
					<img src="../assets/yiBrand/Fill.png" alt="">
				</div>
				<div class="center" style="cursor: pointer;">
					<router-link to="/"><img src="../assets/yiBrand/logo.png" alt=""></router-link>
				</div>
				<div class="right">
					<router-link to="/connect" style="text-decoration: none;color: #000;">有什么可以帮您？</router-link>
				</div>
			</div>
			</div>
			<div class="topImg">
				<div class="videoArea">
					<video src="https://yuyange-prod.oss-cn-hangzhou.aliyuncs.com/miniapp/2022-09-07_15-47-03_10595.mp4" width="1300px" height="534px" class="video_show"
						controls ref="myVideo" preload="auto" autoplay></video>
					<!-- <video src="https://yuyange-prod.oss-cn-hangzhou.aliyuncs.com/miniapp/2022-09-07_15-47-03_10595.mp4" width="1314px" height="534px" class="video_show"
						:controls="controls" ref="myVideo" preload="auto"
						poster="../assets/yiBrand/design/topimg.png"></video> -->
				</div>
				<!-- <div class="open" v-if="ifopen" @click="open()">
					<img src="../assets/yiBrand/design/open.png" alt="">
				</div> -->
			</div>
		</div>
		<el-drawer title="YIQINGTANG一清堂" :visible.sync="drawer" direction="ltr" style="width: 500px;font-size: 20px;"
			size="30%">
			<el-col :span="12">


				<el-menu :router="true" default-active="1" class="el-menu-vertical-demo" @open="handleOpen"
					@close="handleClose">
					<el-submenu index="1">
						<template slot="title">
							
							<span style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">产品</span>
						</template>
						<el-menu-item index="/yiYellow" style="font-family: PingFangRegular;font-size: 14px;">
							倾城秘颜眼部系列</el-menu-item>
						<el-menu-item index="/yiPurple" style="font-family: PingFangRegular;font-size: 14px;">
							嫱颜玉润精粹系列</el-menu-item>
						<el-menu-item index="/yiWhite" style="font-family: PingFangRegular;font-size: 14px;">
							美蝉光感蕴能系列</el-menu-item>
						<el-menu-item index="/yiRed" style="font-family: PingFangRegular;;font-size: 14px;">
							玉奴清滢平衡系列</el-menu-item>
						<el-menu-item index="/yiBlack" style="font-family: PingFangRegular;;font-size: 14px;">
							丽姬凝润冰肌系列</el-menu-item>
					</el-submenu>
					
					<el-menu-item index="/yiStory">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">故事</span>
					</el-menu-item>
					<el-menu-item index="/yiSkin">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">护肤</span>
					</el-menu-item>
					<el-menu-item index="/yiDesign">
						
						<span slot="title" style="font-family: PingFangRegular;font-size: 16px;padding-left: 10px;">设计</span>
					</el-menu-item>
				</el-menu>
			</el-col>


		</el-drawer>


		<div style="background:#fff;">
		<!-- 产品 故事 护肤 -->
		<div class="product">
			<div class="top_text">
				<el-dropdown>
					<span class="text_product">产品</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<router-link to="/yiYellow" style="text-decoration: none;color: #000;">倾城秘颜眼部系列
							</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiPurple" style="text-decoration: none;color: #000;">嫱颜玉润精粹系列
							</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiWhite" style="text-decoration: none;color: #000;">美蝉光感蕴能系列</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiRed" style="text-decoration: none;color: #000;">玉奴清滢平衡系列</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiBlack" style="text-decoration: none;color: #000;">丽姬凝润冰肌系列</router-link>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<span class="text_story">
					<router-link to="/yiStory" style="text-decoration: none;color: #000;">故事</router-link>
				</span>
				<span class="text_skin">
					<router-link to="/yiSkin" style="text-decoration: none;color: #000;">护肤</router-link>
				</span>
				<span class="text_design">
					<router-link to="/yiDesign" style="text-decoration: none;color: #000;">设计</router-link>
				</span>
			</div>
		</div>


		<!-- 品牌文化 -->
		<div class="customer">
			<img src="../assets/yiBrand/design/bg1.png" alt="">
		</div>
		<!-- <div class="customer con">
			<div class="content">
				<div class="top">
					<img src="../assets/yiBrand/design/custom-title.png" alt="">
				</div>
				<div class="detail">
					<img src="../assets/yiBrand/design/detail-title.png" alt="">

				</div>
			</div>
			<div class="EN_content">
				<div class="title">BRAND ORIGIN</div>
				<div class="detail">
					The design of “堂” uses jade seal cutting as the prototype. Upright straight lines and square curves
					are combined to form the shape of a window typical of the East. The upright lines, which stand for
					rules and laws, symbolize the brand’s adherence to the belief that “Nobody witnesses what you have
					done, yet heaven knows what goes in your mind.” The four angels formed by curves indicate female
					strength free from any restrictions. The overall design interprets the brand’s foothold in oriental
					wisdom and the cultural connotation of oriental aesthetics.

				</div>
			</div>
		</div>

		<div style="margin-top:200px;height:185px;background:linear-gradient(to bottom, #9964DA, #ECEAF2, #FCFCFD);"></div> -->

		<!-- 色卡 -->
		<div class="allColor">
			<div class="color">
				<div class="top">
					<div class="top-left">
						<div class="box">
							<img src="../assets/yiBrand/design/top-left.png" alt="">
						</div>
					</div>
					<div class="top-right">
						<div class="box">
							<img src="../assets/yiBrand/design/top-right.png" alt="">
						</div>
					</div>
				</div>
				<div class="center">
					<div class="center-left">
						<div class="title">PANTONE 2725C</div>
						<div class="color-title">#655DC6</div>
					</div>
					<div class="center-c">
						<div class="title">PANTONE 201C</div>
						<div class="color-title">#A32035</div>
					</div>
					<div class="center-right">
						<div class="title">BLACK</div>
						<!-- <div class="color-title">#655DC6</div> -->
					</div>
				</div>
				<div class="bottom">
					<div class="title">WHITE</div>
				</div>
			</div>
		</div>


		<!-- 关于颜色 -->
		<div class="about-color">
			<div class="left">
				BRAND
			</div>
			<div class="center">
				<div class="cleft">关于颜色</div>
				<div class="cright">ABOUT COLOR</div>
			</div>
			<div class="right">
				在品牌色彩上，我们始终坚持带给用户最尊贵的体验。因此我们选取了玄青和乌金两色做为品牌主色。<br />
				<br />
				主色玄青，为贵族专用配色，象征一清堂品牌对东方智慧的传承，融汇与共生象征品牌用户的高端尊享之姿。<br />
				主色乌金在汉文化中为皇家御用色，尊贵高奢的同时隐喻循环不息的平衡之道。<br />
				<br />
				彰显一清堂为用户带来尊享养肤奢品的初心。
			</div>
		</div>

		<div class="centerImg">
			<img src="../assets/yiBrand/design/centerimg.png" alt="">
		</div>



		<!-- 部分VI展示 -->

		<div class="VIshow">
			<div class="title">
				部分VI展示
			</div>
			<div class="Badge">
				<div class="left">
					<img src="../assets/yiBrand/design/Badge.png" alt="">
				</div>
				<div class="right">
					<div class="CN">徽章</div>
					<div class="EN">Badge</div>
				</div>
			</div>
			<div class="Letter">
				<div class="left">
					<div class="CN">信封</div>
					<div class="EN">Letter</div>
				</div>
				<div class="right">
					<img src="../assets/yiBrand/design/Letter.png" alt="">
				</div>
			</div>
			<div class="Pillow">
				<div class="left">
					<img src="../assets/yiBrand/design/Pillow.png" alt="">
				</div>
				<div class="right">
					<div class="CN">抱枕</div>
					<div class="EN">Pillow</div>
				</div>
			</div>
			<div class="Folder">
				<div class="left">
					<div class="CN">文件夹</div>
					<div class="EN">Folder</div>
				</div>
				<div class="right">
					<img src="../assets/yiBrand/design/Folder.png" alt="">
				</div>
			</div>
		</div>


		<!-- 关于VI系统 -->
		<div class="VISystem">
			<div class="content con">
				<div class="detail">
					<div class="CN">
						VI系统概述：企业拥有一套完整的VI系统，<br />
						均使用所设定的标准字体和配色，<br />
						以树立统一、独特、鲜明的企业视觉形象。
					</div>
					<div class="EN">
						System overview: <br />
						The company has an integral VI system, <br />
						using the given standard font and colors<br />
						to build a united,<br />
						unique and strong brand visual image.
					</div>
				</div>
				<div class="center">
					<div class="cleft">关于VI系统</div>
					<div class="cright">ABOUT VI</div>
				</div>
				<div class="right">
					BRAND
				</div>
			</div>
		</div>

		<!-- <div style="height:185px;background:linear-gradient(to top, #9964DA, #ECEAF2, #FCFCFD);"></div> -->

		<div class="bottomImg">
			<img src="../assets/yiBrand/design/bottomimg.png" alt="">
		</div>


		<!-- 关于空间 -->
		<div class="aboutSI">
			<div class="top">
				<div class="left">关于空间</div>
				<div class="right">ABOUT SI</div>
			</div>
			<div class="center">
				一清堂空间将品牌气质与终端视觉完美融合，颜色上以品牌主色玄青搭配东方之即白，象征一清堂品牌对东方智慧的传承、融汇与共生。彰显一清堂品牌用户高端尊享之姿。大量点缀御用乌金，隐喻循环不息的平衡之道与太阳初升的活力光辉。本配色为彰显一清堂品牌为用户带来尊享养肤奢品的初心。
			</div>
			<div class="bottom">
				The space of YQT combines brand temperament with terminal vision perfectly. In color, YQT uses its main
				color -deep black and the first white flight of morning in the east, which symbolizes its inheritance,
				combination and symbiosis for oriental wisdom and indicates the nobleness of its users. Besides, it is
				largely interspersed with imperial golden black, which symbolizes cyclic balance and lively radiance of
				the rising sun. The color blending manifests the original intention of YQT- offering users a luxury
				product of skincare.
			</div>
		</div>


		<!-- 系列推荐 -->
		<div class="recommend">
			<div class="title-wrap">
				<div class="name">系列推荐</div>
				<div class="en">PRODUCT SERIESV</div>
				<div class="line"></div>

			</div>
			<div class="content con">
				<!-- 轮播图 -->
				<div class="prev">
					<img src="../assets/yiBrand/prev.png" @click="arrowClick('prev')" />
				</div>
				<div class="carousel">
					<el-carousel arrow="never" height="510px" :autoplay="true" indicator-position="none" ref="cardShow">
						<el-carousel-item v-for="(item, index) in datalist" :key="index">
							<div class="box">
								<div class="left">
									<div class="cover" @click="goPage(item.color1)">
										<img :src="item.img1" alt="">
									</div>
									<div class="name">{{ item.name1 }}</div>
									<div class="more">
										<span @click="goPage(item.color1)">查看更多</span>
									</div>
								</div>
								<div class="right">
									<div class="cover" @click="goPage(item.color2)">
										<img :src="item.img2" alt="">
									</div>
									<div class="name">{{ item.name2 }}</div>
									<div class="more">
										<span @click="goPage(item.color2)">查看更多</span>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="next">
					<img src="../assets/yiBrand/next.png" @click="arrowClick('next')" />
				</div>

			</div>
		</div>


		<!-- 有什么可以帮您？ -->
		<div class="help">
			<div class="title-wrap con">
				<div class="name">有什么可以帮您的？</div>
			</div>
			<div class="content con">
				<div class="left">
					<img src="../assets/yiBrand/phone.png" alt="">
					<div style="cursor: pointer;">400 853 1616</div>
				</div>
				<div class="right">
					<img src="../assets/yiBrand/email.png" alt="">
					<div style="cursor: pointer;">
						<router-link to="/connect" style="text-decoration: none;color: #000;">联系我们</router-link>
					</div>
				</div>
				
			</div>

		</div>

		<div class="section pageBox6">
			<!-- 底部 -->
			<div class="footerBox con">
				<div class="footerBoxHeader">
					<div class="footerBoxHeaderCenter">
						<img class="codeImg" src="../assets/yiBrand/yiqingtang.png" alt="">
						<span class="codeText">官方微信公众号</span>
					</div>
				</div>

				<div class="footerInfoBox">
					FOLLOW MORAL CULTIVATION<br />
					PURSUE NATURAL IN HEALTH PRESERVATION<br />
				</div>

				<div class="footerBoxBottom">
					<div class="footerLeft">
						Copyright © 2023
						<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备20018890号-4</a>
						<br/>
						<img height="16px" src="../assets/plice.png"/>&nbsp;<a target="_blank"  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33052102000853">浙公网安备 33052102000853号</a>
					</div>
					<div class="footerCenter">
						修有道 养有方
					</div>
					<div class="footerRight">
						<img src="../assets/yiBrand/weibo.png" alt="">
						<img src="../assets/yiBrand/weixin.png" alt="">
					</div>
				</div>
			</div>

			<div class="bottom" style="height:180px;background:linear-gradient(to top, #9D92BF, #ECEAF2, #FCFCFD);">
				</div>
		</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			drawer: false,
			ifopen: true,
			controls: false,
			datalist: [{
				img1: require("../assets/yiBrand/site-1.png"),
				name1: "倾城秘颜眼部系列",
				color1: "yellow",
				img2: require("../assets/yiBrand/site-2.png"),
				name2: "嫱颜玉润精粹系列",
				color2: "purple"
			},
			{
				img1: require("../assets/yiBrand/site-3.png"),
				name1: "美蝉光感蕴能系列",
				color1: "white",
				img2: require("../assets/yiBrand/site-4.png"),
				name2: "玉奴清滢平衡系列",
				color2: "red"
			},
			{
				img1: require("../assets/yiBrand/site-5.png"),
				name1: "丽姬凝润冰肌系列",
				color1: "black",
				img2: require("../assets/yiBrand/site-1.png"),
				name2: "倾城秘颜眼部系列",
				color2: "yellow"
			},

			],
		};
	},

	methods: {
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		open() {
			this.ifopen = false
			this.controls = true
			this.$refs.myVideo.play();
		},

		arrowClick(val) {
			if (val === 'next') {
				this.$refs.cardShow.next()
			} else {
				this.$refs.cardShow.prev()
			}
		},

		goPage(val) {
			switch (val) {
				case 'red':
					this.$router.push({
						path: "/yiRed"
					})
					break;
				case 'purple':
					this.$router.push({
						path: "/yiPurple",
					})
					break;
				case 'black':
					this.$router.push({
						path: "/yiBlack",
					})
					break;
				case 'yellow':
					this.$router.push({
						path: "/yiYellow",
					})
					break;
				case 'white':
					this.$router.push({
						path: "/yiWhite",
					})
					break;
			}
		},
	},
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 300px;
	min-height: 400px;
}

.yiContainer {
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	position: relative;
}

/* pageBox6 */
.pageBox6 {
	margin-top: 100px;
	width: 100%;
	height: 652px;
	/* background-image: url(../assets/yiBrand/bg2.png); */
	/* background-color: red; */
}

/* footer */
.pageBox6 .footerBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 999;
}

.pageBox6 .footerBox .footerBoxHeader {
	margin-top: 115px;
}

.footerBoxHeaderCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.codeImg {
	width: 142px;
}

.codeText {
	width: 222px;
	height: 30px;
	line-height: 30px;
	font-size: 22px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #3F3A60;
	margin-top: 19px;
	text-align: center;
}


.footerInfoBox {
	height: 44px;
	line-height: 22px;
	text-align: center;
	font-size: 14px;
	font-family: PingFangRegular;
	font-weight: 400;
	color: #3F3A60;
	margin-top: 48px;
}


.footerBoxBottom {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	margin-top: 52px;
}

.footerLeft {
	width: 338px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #13005A;
	text-align: center;
}

.footerLeft a {
	text-decoration: none;
	cursor: pointer;
	color: #13005A;
}

/* .footerLeft a:hover {
		color: #fff;
	} */

.footerCenter {
	width: 159px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangRegualr;
	font-weight: 300;
	color: #13005A;
	text-align: center;
	margin-left: 233px;
}

.footerRight {
	margin-left: 369px;
}

.footerRight img {
	width: 24px;
	/* height: 33px; */
	margin-left: 66px;
}

/* 导航 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.section{
	width: 1920px;
		/* background-color: #3F3A60; */
		text-align: center;
}

.head {
	/* height: 96px;
	display: flex;
	flex-direction: row;
	justify-content: space-between; */
	/* align-items: center; */
	width: 1920px;
	margin: 0 auto;
	height: 96px;
	/* align-items: center; */
	position: fixed;
	justify-content: center;
	top: 0;
	right:0;
	bottom:0;
	left:0;

	margin:0 auto;
	/* width: auto; */
	z-index: 999;
	background: #fff;
}

.headtitle{
	width: 1300px;
	margin: 0 auto;
	/* background: #13005A; */
	display: flex;
	flex-direction: row;
	/* margin: 0 auto; */
}

.head .left img:first-child{
	width: 38px;
	/* height: 25px; */
	margin-right: 45px;
	cursor: pointer;
	margin-top: 40px;
}


.head .left img:last-child{
	width: 24px;
}
.head .center img{
	width: 341px;
	height: 30px;
	margin-top: 38px;
	margin-left: 370px;
}

.head .right {
	margin-left: 369px;
	margin-top: 43px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #3F3A60;
	line-height: 20px;
}


/* 首图 */
.topImg {
	width: 100%;
	/* position: relative; */
	margin-top: 96px;
}

.topImg .videoArea {
	/* padding: 0 310px; */
	width: 1300px;
	height: 534px;
	background-color: #000;
	position: fixed;
	top: 0;
	right:0;
	bottom:0;
	left:0;
	margin:0 auto;
 	z-index: -1;
	max-height: 534px;
	transition-property: all;
	margin-top: 96px;
}

/* .topImg .open {
	position: absolute;
	top: 232px;
	left: 628px;
	cursor: pointer;
} */
.topImg .videoArea .video_show {
	margin: 0 auto;
}





/* 产品 故事 护肤 */
.product {
	margin-top: 664px;
	box-shadow: 0px -20px 50px white;
}

.product .top_text {
	text-align: center;
	/* margin-bottom: 53px; */
}

.product .top_text span {
	font-size: 16px;
	font-family: PingFnagLight;
	font-weight: 100;
	color: #000000;
	letter-spacing: 1px;
}

.product .top_text .text_product {
	margin-right: 40px;
	cursor: pointer;
}

.product .top_text .text_story {
	margin-right: 40px;
	cursor: pointer;
}

.product .top_text .text_skin {
	cursor: pointer;
}


.product .top_text .text_design {
	margin-left: 40px;
	cursor: pointer;
	padding-bottom: 5px;
	border-bottom: 1px solid #979797;
}


/* 品牌文化 */
.customer {
	margin-top: 6px;
	width: 100%;
	position: relative;
	/* background-image: url(../assets/yiBrand/bg1.png); */
	/* overflow: hidden; */
	text-align: center;
}

.customer img{
	/* width: 1920px; */
	height: 900px;
}

.customer .content {
	display: flex;
	flex-direction: row;
	/* background-color: #3F3A60; */
	/* justify-content: space-between; */
	/* background-color: #3F3A60; */
}

.customer .content .top{
	/* margin-top: 100px; */
	position: relative;
	left: 90px;
	top: 120px;
}

.customer .content .top img{
	width: 71px;
	height: 362px;
}

.customer .content .detail {
	margin-top: 236px;
	margin-left: 516px;
}

.customer .content .detail img{
	width: 685px;
	height: 221px;
}

.customer .EN_content {
	width: 1100px;
	margin: 0 auto;
	margin-top: 191px;
}

.customer .EN_content .title {
	height: 35px;
	font-size: 21px;
	font-family: PingFangRegular;
	font-weight: 300;
	color: #A48D29;
	line-height: 35px;
}

.customer .EN_content .detail {
	width: 1198px;
	height: 125px;
	margin-top: 26px;
	font-size: 18px;
	font-family: PingFangLight;
	font-weight: 100;
	color: #A48D29;
	line-height: 25px;
	word-break: break-all;
}



/* 色卡 */
.allColor {
	/* padding-bottom: 0px; */
	/* margin-top: -20px; */
	height: 940px;
	background: linear-gradient(180deg, #F2DEC0 0%, #ECEAF2 28%, #FCFCFD 100%);
}

.allColor .color {
	width: 1300px;
	margin: 0 auto;
	height: 1017px;
	padding-top: 138px;
	/* margin-top: 138px; */
}

.allColor .color .top {
	display: flex;
}

.allColor .color .top .top-left {
	width: 650px;
	height: 597px;
	background: #3C3A5D;
}

.allColor .color .top .top-left .box {
	margin-top: 48px;
	margin-left: 55px;
}

.allColor .color .top .top-right {
	width: 650px;
	height: 597px;
	background: #9E882D;
}

.allColor .color .top .top-right .box {
	margin-top: 48px;
	margin-left: 55px;
}

.allColor .color .center {
	display: flex;
	color: #FEFEFE;
}

.allColor .color .center .title {
	margin-top: 50px;
	margin-left: 50px;
	font-size: 18px;
}

.allColor .color .center .color-title {
	margin-top: 8px;
	margin-left: 50px;
	font-size: 12px;
}

.allColor .color .center .center-left {
	width: 434px;
	height: 210px;
	background: #655DC6;
}

.allColor .color .center .center-c {
	width: 434px;
	height: 210px;
	background: #A32035;
}

.allColor .color .center .center-right {
	width: 434px;
	height: 210px;
	background: #000;
}

.allColor .color .bottom {
	width: 1298px;
	height: 210px;
	background: #fff;
	border: 1px solid #9E882D;
}

.allColor .color .bottom .title {
	margin-top: 50px;
	margin-left: 29px;
	color: #040201;
	font-size: 18px;
	font-weight: 800;
}


/* 关于颜色 */
.about-color {
	display: flex;
	justify-content: center;
	margin-top: 262px;
	/* margin-bottom: 128px; */
	/* background-color: yellow; */
}

.about-color .left {
	margin-left: 29px;
	margin-top: 93px;
	width: 88px;
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #333;
	line-height: 33px;
}

.about-color .center {
	margin-top: 104px;
	margin-left: 121px;
	writing-mode: vertical-lr;
}

.about-color .center .cleft {
	width: 22px;
	height: 128px;
	font-size: 30px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #333;
	line-height: 32px;
	letter-spacing: 1px;
}

.about-color .center .cright {
	margin-left: 14px;
	width: 30px;
	height: 173px;
	font-size: 22px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #333;
	line-height: 30px;
	letter-spacing: 1px;
}

.about-color .right {
	margin-top: 128px;
	margin-left: 307px;
	width: 688px;
	height: 200px;
	font-size: 18px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #333;
	line-height: 25px;
}

.centerImg {
	width: 1300px;
	margin: 0 auto;
	margin-top: 128px;
}

.centerImg img{
	width: 1299px;
	height: 432px;
	
}


/* 部分VI展示 */
.VIshow {
	width: 1200px;
	margin: 0 auto;
	margin-top: 116px;
}

.VIshow .title {
	height: 48px;
	font-size: 34px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #333333;
	line-height: 48px;
}

.VIshow .Badge {
	display: flex;
	margin-top: 37px;
}

.VIshow .left {
	width: 600px;
	height: 324px;
	overflow: hidden;
}

.VIshow .right img,
.VIshow .left img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s
}

.VIshow .right img:hover,
.VIshow .left img:hover {
	transform: scale(1.1);
}

.VIshow .right {
	width: 600px;
	height: 324px;
	overflow: hidden;
}

.VIshow .Letter,
.VIshow .Pillow,
.VIshow .Folder {
	display: flex;
}

.VIshow .Badge .CN {
	margin-left: 67px;
	/* width: 68px; */
	height: 48px;
	font-size: 34px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #444444;
	line-height: 48px;
}

.VIshow .Badge .EN {
	margin-left: 74px;
	/* width: 73px; */
	height: 33px;
	font-size: 24px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #444444;
	line-height: 33px;
}

.VIshow .Letter .CN {
	margin-top: 213px;
	margin-left: 1px;
	/* width: 68px; */
	height: 48px;
	font-size: 34px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #444444;
	line-height: 48px;
}

.VIshow .Letter .EN {
	margin-left: 1px;
	/* width: 72px; */
	height: 33px;
	font-size: 24px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #444444;
	line-height: 33px;
}

.VIshow .Pillow .CN {
	margin-top: 216px;
	margin-left: 74px;
	/* width: 68px; */
	height: 48px;
	font-size: 34px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #444444;
	line-height: 48px;
}

.VIshow .Pillow .EN {
	margin-left: 76px;
	/* width: 76px; */
	height: 33px;
	font-size: 24px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #444444;
	line-height: 33px;
}

.VIshow .Folder .CN {
	margin-top: 206px;
	margin-left: 1px;
	/* width: 102px; */
	height: 48px;
	font-size: 34px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #444444;
	line-height: 48px;
}

.VIshow .Folder .EN {
	margin-left:1px;
	/* width: 77px; */
	height: 33px;
	font-size: 24px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #444444;
	line-height: 33px;
}

/* 关于VI系统 */
.VISystem {
	margin-top: 184px;
	
}

.VISystem .content {
	display: flex;
}

.VISystem .content .detail .CN {
	margin-left: 50px;
	height: 87px;
	font-size: 21px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #444444;
	line-height: 29px;
}

.VISystem .content .detail .EN {
	margin-top: 45px;
	margin-left: 50px;
	height: 140px;
	font-size: 20px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #444444;
	line-height: 28px;
}

.VISystem .content .center {

	margin-left: 445px;
	writing-mode: vertical-lr;
}

.VISystem .content .center .cleft {
	width: 40px;
	height: 250px;
	font-size: 34px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #444444;
	line-height: 50px;
	letter-spacing: 8px;
}

.VISystem .content .center .cright {
	margin-left: 14px;
	/* width: 37px; */
	height: 150px;
	font-size: 24px;
	font-family:  PingFangEXTRALIGHT;
	font-weight: 100;
	color: #444444;
	line-height: 30px;
	letter-spacing: 2px;
}

.VISystem .content .right {

	margin-left: 114px;
	/* width: 69px; */
	height: 2px;
	font-size: 18px;
	font-family:  PingFangEXTRALIGHT;
	font-weight: 100;
	color: #444444;
	line-height: 25px;
}

/* 关于空间 */
.aboutSI {
	width: 1300px;
	margin: 0 auto;
}

.aboutSI .top {
	display: flex;
}

.aboutSI .top .left {
	margin-top: 78px;
	margin-left: 15px;
	width: 138px;
	height: 45px;
	font-size: 32px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 45px;
	letter-spacing: 1px;
}

.aboutSI .top .right {
	margin-top: 94px;
	margin-left: 13px;
	/* width: 98px; */
	height: 25px;
	font-size: 18px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #A48D29;
	line-height: 25px;
	letter-spacing: 1px;
}

.aboutSI .center {
	margin-top: 27px;
	margin-left: 15px;
	width: 1252px;
	height: 105px;
	font-size: 21px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #A48D29;
	line-height: 29px;
}

.aboutSI .bottom {
	margin-top: 30px;
	margin-left: 15px;
	width: 1261px;
	height: 100px;
	font-size: 18px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #A48D29;
	line-height: 25px;
	letter-spacing: 1px;
	word-break: break-all;
}

.bottomImg {
	width: 1920px;
	margin: 0 auto;
	margin-top: 184px;
}


/* 系列推荐 */
.recommend {
	margin-top: 109px;
	height: 685px;
	/* background-color: #A48D29; */
}



.recommend .title-wrap {
	text-align: center;
	/* color: #A48D29; */
	margin-bottom: 20px;
}



.recommend .title-wrap .name {
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 33px;
}


.recommend .title-wrap .en {
	font-family: PingFangEXTRALIGHT;
	font-size: 16px;
	margin-top: 3px;
	height: 22px;
	line-height: 22px;
	letter-spacing: 2px;
	color: #A48D29;
}


.recommend .title-wrap .line {
	width: 34px;
	height: 1px;
	background-color: #A48D29;
	margin: auto;
	margin-top: 10px;
}


.recommend .content {
	position: relative;
}

.recommend .content .prev {
	position: absolute;
	left: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;

}

.recommend .content .prev img{
	width: 40px;
	height: 40px;
}

.recommend .content .next {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;
}

.recommend .content .next img{
	width: 40px;
	height: 40px;
}

.recommend .content .box {
	padding: 0 125px;
	display: flex;
	justify-content: space-between;
}

.recommend .content .cover {
	margin-top: 20px;
	width: 518px;
	height: 332px;
	overflow: hidden;
}

.recommend .content .cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s;
	cursor: pointer;
}

.recommend .content .cover img:hover {
	transform: scale(1.1);
}

.recommend .content .box .name {
	height: 29px;
	font-size: 21px;
	margin-top: 10px;
	text-align: center;
	font-family: PingFangLight;
	font-weight: 300;
	color: #595857;
	line-height: 29px;
}

.recommend .content .box .more {
	margin-top: 17px;
}

.recommend .content .box .more span {
	width: 105px;
	height: 27px;
	font-size: 15px;
	font-family: PingFangEXTRALIGHT;
	line-height: 27px;
	padding: 3px 21px 2px 21px;
	border: 1px solid #c1c1c1;
	cursor: pointer;
	margin: 0px 208px;
}


/* 帮助 */

.help {
	/* margin-top: 160px; */
	padding-bottom: 120px;
	border-bottom: 1px solid #797979;
}

.help .title-wrap {
	text-align: center;
	height: 33px;
	line-height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
}

.help .content {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* background-color: #A48D29; */
	/* align-items: center; */
}


.help .content .right,
.help .content .left {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.help .content .left{
	margin-right: 100px;
}

.help .content .left img {
	width: 28px;
	height: 52px;
	margin-right: 19px;
}

.help .content div {
	font-family: PingFangEXTRALIGHT;
	font-size: 20px;
	color: #666;
	font-weight: 100;
}

.help .content .right img{
	width: 52px;
	height: 32px;
	margin-right: 18px;
}


.con {
	width: 1300px;
	margin: 0 auto;
}
</style>
