<template>
	<div class="yiContainer">
		<!-- 导航 -->
		<div class="section">
			<div class="head">
				<!-- <div class="left">
						<img src="../assets/yiBrand/search.png" alt="">
						<img src="../assets/yiBrand/Fill.png" alt="">
					</div> -->
				<div class="center" style="cursor: pointer;">
					<router-link to="/"><img src="../assets/yiBrand/red/title11.png" alt=""></router-link>
				</div>
				<!-- <div class="right">
						有什么可以帮您？
					</div> -->
			</div>

			<div class="topImg">
				<img src="../assets/yiBrand/black/topimg.jpg" alt="">
			</div>
		</div>


		<div style="background:#fff;">
		<!-- 产品 故事 护肤 -->
		<div class="product">
			<div class="top_text">
				<el-dropdown>
					<span class="text_product">产品</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<router-link to="/yiYellow" style="text-decoration: none;color: #000;">倾城秘颜眼部系列
							</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiPurple" style="text-decoration: none;color: #000;">嫱颜玉润精粹系列
							</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiWhite" style="text-decoration: none;color: #000;">美蝉光感蕴能系列</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiRed" style="text-decoration: none;color: #000;">玉奴清滢平衡系列</router-link>
						</el-dropdown-item>
						<el-dropdown-item>
							<router-link to="/yiBlack" style="text-decoration: none;color: #000;">丽姬凝润冰肌系列</router-link>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<span class="text_story">
					<router-link to="/yiStory" style="text-decoration: none;color: #000;">故事</router-link>
				</span>
				<span class="text_skin">
					<router-link to="/yiSkin" style="text-decoration: none;color: #000;">护肤</router-link>
				</span>
				<span class="text_design">
					<router-link to="/yiDesign" style="text-decoration: none;color: #000;">设计</router-link>
				</span>
			</div>

			<div class="allProduct">
				<div style="height:185px;background: linear-gradient(to bottom, #9964DA , #ECEAF2 , #FCFCFD);">
				</div>
				<div class="showProduct con">
					<div class="detailProduct con">
						<div class="detail">
							<div class="price" style="margin-left:18px;">12片*25ML / 0.88FL.OZ</div>
							<div class="line" style="margin-left:70px;"></div>
							<div class="name" style="margin-left:-18px;margin-top: 36px;">MOSTURIZING AND
								DELICATE<br />FACIAL MASK<br /><span style="font-family:PingFangLight;">一清堂凝润冰肌面膜</span>
							</div>
							<div class="cover">
								<img src="../assets/yiBrand/black/product1.png" alt="">
							</div>
						</div>
						<div class="detail" style="margin-left: -10px;">
							<div class="price" style="margin-left:-28px;">120ML / 4.22FL.OZ</div>
							<div class="line" style="margin-left:10px;"></div>
							<div class="name" style="margin-left:-36px; margin-top: 36px;">MOSTURIZING AND<br />DELICATE
								TONER<br /><span style="font-family:PingFangLight;">一清堂凝润冰肌水</span></div>
							<div class="cover" style="margin-left:4px;">
								<img src="../assets/yiBrand/black/product2.png" alt="">
							</div>
						</div>
						<div class="detail" style="margin-left: 40px;">
							<div class="price" style="margin-left:-14px;">80ML / 2.82FL.OZ</div>
							<div class="line" style="margin-left:18px;"></div>
							<div class="name" style="margin-left:-66px;margin-top: 36px;">MOSTURIZING AND
								DELICATE<br />COLLAGENIC SERUM<br /><span style="font-family:PingFangLight;">一清堂凝润冰肌胶原蛋白液</span></div>
							<div class="cover" style="margin-left:17px;">
								<img style="height:170px;" src="../assets/yiBrand/black/product3.png" alt="">
							</div>
						</div>
						<div class="detail">
							<div class="price" style="margin-left:-8px;">80ML / 2.82FL.OZ</div>
							<div class="line" style="margin-left:22px;"></div>
							<div class="name" style="margin-left:-26px; margin-top: 36px;">MOSTURIZING AND<br />DELICATE
								SERUM<br /><span style="font-family:PingFangLight;">一清堂凝润冰肌精华液</span></div>
							<div class="cover" style="margin-left:13px;">
								<img style="height:170px;" src="../assets/yiBrand/black/product4.png" alt="">
							</div>
						</div>
						<div class="detail">
							<div class="price" style="margin-left:32px;">50G / 1.76FL.OZ</div>
							<div class="line" style="margin-left:58px;"></div>
							<div class="name" style="margin-left:-24px; margin-top: 32px;">MOSTURIZING AND
								DELICATE<br />BEDTIME FACIAL MASK<br /><span style="font-family:PingFangLight;">一清堂凝润冰肌睡眠面膜</span>
							</div>
							<div class="cover" style="margin-left:26px;">
								<img style="height:106px;" src="../assets/yiBrand/black/product5.png" alt="">
							</div>
						</div>
						<div class="detail">
							<div class="price" style="margin-left:-40px;">冻干粉100mgx10瓶&溶媒液3mlx10瓶
							</div>
							<div class="line" style="margin-left:34px;"></div>
							<div class="name" style="margin-left:-44px; margin-top: 34px;">MOISTURIZING AND
								DELICATE<br />LYOPHILIZED POWDER<br /><span style="font-family:PingFangLight;">一清堂凝润冰肌冻干粉+溶媒液</span>
							</div>
							<div class="cover"  style="margin-left:12px;">
								<img src="../assets/yiBrand/black/product6.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>

		<!-- 品牌文化 -->
		<div class="customer con">
			<div class="content">
				<div class="left">
					<div class="top-title">
						花开几度春 玉面如初见
					</div>
					<div class="center-title">
						留驻时光，御美有术。<br /><br/>
						适用人群：<span style="font-family:PingFangEXTRALIGHT;">轻熟肌</span><br />
						适用肤质：<span style="font-family:PingFangEXTRALIGHT;">油性及中干性肌肤</span><br />
					</div>
					<div class="bottom-title">
						一贴赋能，润泽肌底：<span style="font-family:PingFangEXTRALIGHT;">凝润冰肌面膜12片*25ml</span><br />
						沁透凝肤，嘭润上扬：<span style="font-family:PingFangEXTRALIGHT;">凝润冰肌水*120ml</span><br />
						嘭润补水，丰盈焕肤：<span style="font-family:PingFangEXTRALIGHT;">凝润冰肌精华液*80ml</span><br />
						胶原嘭弹，莹润满级：<span style="font-family:PingFangEXTRALIGHT;">凝润冰肌胶原蛋白液*80ml</span><br />
						高阶润泽，加乘修润：<br /><span
							style="font-family:PingFangEXTRALIGHT;">凝润冰肌冻干粉100mg*10瓶+溶媒液3ml*10瓶</span><br />
						凝萃补水，唤启光采：<span style="font-family:PingFangEXTRALIGHT;">凝润冰肌睡眠面膜*50g</span><br />
					</div>
				</div>
				<div class="right">
					<div class="title">
						丽姬凝润冰肌系列
					</div>
					<!-- 轮播图 -->
					<div class="content">

						<div class="prev">
							<img src="../assets/yiBrand/prev.png" @click="Click('prev')" />
						</div>
						<div class="carousel">
							<el-carousel arrow="never" height="510px" :autoplay="true" indicator-position="none"
								ref="imgShow">
								<el-carousel-item v-for="(item, index) in productlist" :key="index">
									<div class="cover">
										<img :src="item.img" alt="">
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="next">
							<img src="../assets/yiBrand/next.png" @click="Click('next')" />
						</div>
					</div>
				</div>
			</div>

			<div class="allProduct con">
				<div class="detail">
					<div class="price" style="margin-left:-36px;">120ml / 4.2FL.OZ</div>
					<div class="line" style="margin-left:11px;"></div>
					<div class="name" style="margin-left:-44px; margin-top: 20px;">MOISTURIZING<br>ESSENTIAL
						TONER<br><span style="font-family:PingFangLight;">一清堂玉润精萃水</span></div>
					<!-- <div class="star" style="margin-left:-14px;margin-top: 26px;">•明星甄选•</div> -->
					<div class="cover">
						<img style="height:228px;" src="../assets/yiBrand/red/product1.png" alt="">
					</div>
				</div>
				<div class="detail">
					<div class="price" style="margin-left:-32px;">120ml / 4.2FL.OZ</div>
					<div class="line" style="margin-left:21px;"></div>
					<div class="name" style="margin-left:-35px;margin-top:20px ;">YIQINGTNAG<br>CLEAR
						CLEANSER<br><span style="font-family:PingFangLight;">一清堂清滢洁面露</span></div>
					<!-- <div class="star" style="margin-left:-3px; margin-top: 26px;">•明星甄选•</div> -->
					<div class="cover">
						<img style="height: 228px;" src="../assets/yiBrand/red/product2.png" alt="">
					</div>
				</div>
				<div class="detail">
					<div class="price" style="margin-left:15px;">12片*25ML / 0.88FL.OZ</div>
					<div class="line" style="margin-left:89px;"></div>
					<div class="name" style="margin-right:59px;margin-top: 20px;">MOISTURIZING ESSENTIAL<br />FACIAL
						MASK<br /><span style="font-family:PingFangLight;">一清堂玉润精萃面膜</span>
					</div>
					<!-- <div class="star" style="margin-left:54px;margin-top: 26px;">•明星甄选•</div> -->
					<div class="cover" style="margin-bottom:-2px;margin-left: 10px;">
						<img src="../assets/yiBrand/red/product3.png" alt="">
					</div>
				</div>
				<div class="detail">
					<div class="price" style="margin-left:-43px;">120ml / 4.2FL.OZ</div>
					<div class="line" style="margin-left:10px;"></div>
					<div class="name" style="margin-left:-48px;margin-top: 20px;">CLEAR
						BALANCING<br />TONER<br /><span style="font-family:PingFangLight;">一清堂清滢平衡水</span></div>
					<!-- <div class="star" style="margin-left:-18px;margin-top: 16px;">•明星甄选•</div> -->
					<div class="cover" style="margin-left:-10px;margin-bottom:-16px;">
						<img style="height: 252px;" src="../assets/yiBrand/red/product4.png" alt="">
					</div>




				</div>
			</div>
		</div>

		<!-- 系列推荐 -->
		<div class="recommend">
			<div class="title-wrap">
				<div class="name">系列推荐</div>
				<div class="en">PRODUCT SERIESV</div>
				<div class="line"></div>

			</div>
			<div class="content con">
				<!-- 轮播图 -->
				<div class="prev">
					<img src="../assets/yiBrand/prev.png" @click="arrowClick('prev')" />
				</div>
				<div class="carousel">
					<el-carousel arrow="never" height="510px" :autoplay="true" indicator-position="none" ref="cardShow">
						<el-carousel-item v-for="(item, index) in datalist" :key="index">
							<div class="box">
								<div class="left">
									<div class="cover" @click="goPage(item.color1)">
										<img :src="item.img1" alt="">
									</div>
									<div class="name">{{ item.name1 }}</div>
									<div class="more">
										<span @click="goPage(item.color1)">查看更多</span>
									</div>
								</div>
								<div class="right">
									<div class="cover" @click="goPage(item.color2)">
										<img :src="item.img2" alt="">
									</div>
									<div class="name">{{ item.name2 }}</div>
									<div class="more">
										<span @click="goPage(item.color2)">查看更多</span>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="next">
					<img src="../assets/yiBrand/next.png" @click="arrowClick('next')" />
				</div>

			</div>
		</div>


		<!-- 有什么可以帮您？ -->
		<div class="help">
			<div class="title-wrap con">
				<div class="name">有什么可以帮您的？</div>
			</div>
			<div class="content con">
				<div class="left">
					<img src="../assets/yiBrand/phone.png" alt="">
					<div style="cursor: pointer;">400 853 1616</div>
				</div>
				<div class="right">
					<img src="../assets/yiBrand/email.png" alt="">
					<div style="cursor: pointer;">
						<router-link to="/connect" style="text-decoration: none;color: #000;">联系我们</router-link>
					</div>
				</div>
				
			</div>

		</div>

		<div class="section pageBox6">
			<!-- 底部 -->
			<div class="footerBox con">
				<div class="footerBoxHeader">
					<div class="footerBoxHeaderCenter">
						<img class="codeImg" src="../assets/yiBrand/yiqingtang.png" alt="">
						<span class="codeText">官方微信公众号</span>
					</div>
				</div>

				<div class="footerInfoBox">
					FOLLOW MORAL CULTIVATION<br />
					PURSUE NATURAL IN HEALTH PRESERVATION<br />
				</div>

				<div class="footerBoxBottom">
					<div class="footerLeft">
						Copyright © 2023
						<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备20018890号-4</a>
						<br/>
						<img height="16px" src="../assets/plice.png"/>&nbsp;<a target="_blank"  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33052102000853">浙公网安备 33052102000853号</a>
					</div>
					<div class="footerCenter">
						修有道 养有方
					</div>
					<div class="footerRight">
						<img src="../assets/yiBrand/weibo.png" alt="">
						<img src="../assets/yiBrand/weixin.png" alt="">
					</div>
				</div>
			</div>
			<div class="bottom" style="height:180px;background:linear-gradient(to top, #9D92BF, #ECEAF2, #FCFCFD);">
				</div>
		</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			productlist:[
				{img:require("../assets/yiBrand/black/Image-1.jpeg")},
				{img:require("../assets/yiBrand/black/Image-2.jpeg")},
				{img:require("../assets/yiBrand/black/Image-3.jpeg")},
				{img:require("../assets/yiBrand/black/Image-4.jpeg")},
				{img:require("../assets/yiBrand/black/Image-5.jpeg")},
				{img:require("../assets/yiBrand/black/Image-6.jpeg")},
			],
			datalist: [{
				img1: require("../assets/yiBrand/site-1.png"),
				name1: "倾城秘颜眼部系列",
				color1: "yellow",
				img2: require("../assets/yiBrand/site-2.png"),
				name2: "嫱颜玉润精粹系列",
				color2: "purple"
			},
			{
				img1: require("../assets/yiBrand/site-3.png"),
				name1: "美蝉光感蕴能系列",
				color1: "white",
				img2: require("../assets/yiBrand/site-4.png"),
				name2: "玉奴清滢平衡系列",
				color2: "red"
			},
			{
				img1: require("../assets/yiBrand/site-5.png"),
				name1: "丽姬凝润冰肌系列",
				color1: "black",
				img2: require("../assets/yiBrand/site-1.png"),
				name2: "倾城秘颜眼部系列",
				color2: "yellow"
			},

			],
			brandActive: 1,
		};
	},
	methods: {
		arrowClick(val) {
			if (val === 'next') {
				this.$refs.cardShow.next()
			} else {
				this.$refs.cardShow.prev()
			}
		},
		Click(val) {
			if (val === 'next') {
				this.$refs.imgShow.next()
			} else {
				this.$refs.imgShow.prev()
			}
		},
		goPage(val) {
			switch (val) {
				case 'red':
					this.$router.push({
						path: "/yiRed"
					})
					break;
				case 'purple':
					this.$router.push({
						path: "/yiPurple",
					})
					break;
				case 'black':
					this.$router.push({
						path: "/yiBlack",
					})
					break;
				case 'yellow':
					this.$router.push({
						path: "/yiYellow",
					})
					break;
				case 'white':
					this.$router.push({
						path: "/yiWhite",
					})
					break;
			}
		},
	},
};
</script>

<style scoped>
.yiContainer {
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	position: relative;
}

/* pageBox6 */
.pageBox6 {
	margin-top: 100px;
	width: 100%;
	height: 652px;
	/* background-image: url(../assets/yiBrand/bg2.png); */
	/* background-color: red; */
}

/* footer */
.pageBox6 .footerBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 999;
}

.pageBox6 .footerBox .footerBoxHeader {
	margin-top: 115px;
}

.footerBoxHeaderCenter {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.codeImg {
	width: 142px;
}

.codeText {
	width: 222px;
	height: 30px;
	line-height: 30px;
	font-size: 22px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #3F3A60;
	margin-top: 19px;
	text-align: center;
}


.footerInfoBox {
	height: 44px;
	line-height: 22px;
	text-align: center;
	font-size: 14px;
	font-family: PingFangRegular;
	font-weight: 400;
	color: #3F3A60;
	margin-top: 48px;
}


.footerBoxBottom {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	margin-top: 52px;
}

.footerLeft {
	width: 338px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #13005A;
	text-align: center;
}

.footerLeft a {
	text-decoration: none;
	cursor: pointer;
	color: #13005A;
}

/* .footerLeft a:hover {
		color: #fff;
	} */

.footerCenter {
	width: 159px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	font-family: PingFangRegualr;
	font-weight: 300;
	color: #13005A;
	text-align: center;
	margin-left: 233px;
}

.footerRight {
	margin-left: 369px;
}

.footerRight img {
	width: 28px;
	/* height: 33px; */
	margin-left: 66px;
}

/* 导航 */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.section{
		width: 1920px;
		/* background-color: #3F3A60; */
		text-align: center;
	}

.head {
	width: 1920px;
	height: 96px;
	position: fixed;
	justify-content: center;
	top: 0;
	z-index: 999;
	background: #fff;
	/* display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center; */
}

.head .center img {
	width: 409px;
	height: 36px;
	margin-top: 26px;
}

/* .head .left img {
		margin-right: 49px;
	} */

/* 	.head .right {
		height: 20px;
		font-size: 14px;
		font-family: SourceHanSerifCN-Medium;
		font-weight: 500;
		color: #3F3A60;
		line-height: 20px;
	} */



/* 首图 */
.topImg {
	margin-top: 96px;
	width: 100%;
	position: fixed;
 	background-color: #fff;
 	z-index: -1;
	max-height: 529px;
	transition-property: all;
}

.topImg img {
	/* padding: 0 310px; */
	width: 1304px;
	height: 529px;
}



/* 产品 故事 护肤 */
.product {
	margin-top: 664px;
	box-shadow: 0px -20px 50px white;
}

.product .top_text {
	text-align: center;
	margin-bottom: 53px;
}

.product .top_text span {
	font-size: 16px;
	font-family: PingFangLight;
	font-weight: 100;
	color: #000000;
	letter-spacing: 1px;
}

.product .top_text .text_product {
	cursor: pointer;
	margin-right: 40px;
	padding-bottom: 5px;
	border-bottom: 1px solid #979797;
}

.product .top_text .text_story {
	cursor: pointer;
	margin-right: 40px;
}

.product .top_text .text_skin {
	cursor: pointer;
	margin-right: 40px;
}

.product .top_text .text_design {
	cursor: pointer;
}


.product .allProduct {
	/* position: relative; */
	height: 580px;
	/* background: linear-gradient(to bottom, #9D92BF, #ECEAF2, #FCFCFD); */
}

.product .allProduct .showProduct {
	position: relative;
	height: 400px;
	/* background-color: #3F3A60; */
}

.product .allProduct .showProduct .detailProduct {
	/* width: 1300px; */
	position: absolute;
	bottom: 92px;
	left: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* background-color: #EBA998; */
}

.customer .allProduct .detail,
.product .allProduct .showProduct .detail {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
}


.customer .allProduct .detail .star {
	height: 25px;
	line-height: 25px;
	font-size: 18px;
	font-family: PingFangEXTRALIGHT;
	font-weight: 100;
	color: #000;
	letter-spacing: 1px;
}

.customer .allProduct .detail .name {
	height: 66px;
	line-height: 22px;
	font-size: 16px;
	text-align: center;
	font-family: PingFangLight;
	font-weight: 300;
	letter-spacing: 1px;
	color: #000;
}

.product .allProduct .showProduct .detail .name {
	font-size: 13.5px;
	text-align: center;
	font-family: PingFangEXTRALIGHT;
	font-weight: 400;
	letter-spacing: 1px;
	color: #000;
	height: 66px;
	line-height: 22px;
}

.customer .allProduct .detail .line,
.product .allProduct .showProduct .detail .line {
	width: 43px;
	height: 1px;
	background: #979797;
	margin: 15px auto 0px;
}

.customer .allProduct .detail .price {
	height: 22px;
	line-height: 22px;
	margin-top: 19px;
	text-align: center;
	font-family: PingFangEXTRALIGHT;
	font-size: 16px;
	font-weight: 100;
	color: #000;
}

.product .allProduct .showProduct .detail .price {
	height: 19px;
	line-height: 19px;
	margin-top: 13.5px;
	text-align: center;
	font-family: PingFangEXTRALIGHT;
	font-size: 1px;
	font-weight: 100;
	color: #000;
}




/* 品牌文化 */
.customer {
	margin-top: 80px;
	/* width: 100%; */
	height: 1156px;
	position: relative;
	/* background-image: url(../assets/yiBrand/red/5555.png); */
	/* overflow: hidden; */
	/* background-color: #EBA998; */
}


.customer .content .left {
	position: absolute;
	left: 42px;
	top: 143px;
}

.customer .content .left .top-title {
	font-size: 18px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #000000;
}

.customer .content .left .center-title {
	margin-top: 54px;
	height: 120px;
	line-height: 30px;
	font-size: 14px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #000000;
}

.customer .content .left .bottom-title {
	margin-top: 22px;
	height: 210px;
	line-height: 30px;
	font-size: 14px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #000000;
}


.customer .content .right {
	position: absolute;
	left: 721px;
	top: 62px;
	display: flex;
	flex-direction: row;
}

.customer .content .right .title {
	/* writing-mode: tb-rl; */
	font-size: 18px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #000000;
	width: 16px;
	height: 200px;
	line-height: 25px;
}

.customer .content .right .content {
	position: relative;
}

.customer .content .right .content .prev {
	position: absolute;
	left: 34px;
	top: 240px;
	z-index: 999;
	cursor: pointer;
}

.customer .content .right .content .next {
	position: absolute;
	left: 500px;
	top: 240px;
	z-index: 999;
	cursor: pointer;
}

.customer .content .right .content .prev img{
	width: 40px;
	height: 40px;
}
.customer .content .right .content .next img{
	width: 40px;
	height: 40px;
}

.customer .content .right .content .carousel {
	position: absolute;
	top: 2px;
	left: 104px;
	width: 363px;
	height: 543px;
}

.customer .content .right .content .carousel .cover {
	width: 363px;
	height: 543px;
	overflow: hidden;
}

.customer .content .right .content .carousel .cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s;
}

.customer .content .right .content .carousel .cover img:hover {
	transform: scale(1.1);
}


.customer .allProduct {
	position: absolute;
	top: 638px;
	left: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}


/* 系列推荐 */
.recommend {
	margin-top: 66px;
	height: 685px;
	/* background-color: #A48D29; */
}



.recommend .title-wrap {
	text-align: center;
	/* color: #A48D29; */
	margin-bottom: 20px;
}



.recommend .title-wrap .name {
	height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
	line-height: 33px;
}


.recommend .title-wrap .en {
	font-family: PingFangEXTRALIGHT;
	font-size: 16px;
	margin-top: 3px;
	height: 22px;
	line-height: 22px;
	letter-spacing: 2px;
	color: #A48D29;
}


.recommend .title-wrap .line {
	width: 34px;
	height: 1px;
	background-color: #A48D29;
	margin: auto;
	margin-top: 10px;
}


.recommend .content {
	position: relative;
}

.recommend .content .prev {
	position: absolute;
	left: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;

}

.recommend .content .prev img{
	width: 40px;
	height: 40px;
}

.recommend .content .next {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 66px;
	top: 30%;
	z-index: 999;
	cursor: pointer;
}

.recommend .content .next img{
	width: 40px;
	height: 40px;
}

.recommend .content .box {
	padding: 0 125px;
	display: flex;
	justify-content: space-between;
}

.recommend .content .cover {
	margin-top: 20px;
	width: 518px;
	height: 332px;
	overflow: hidden;
}

.recommend .content .cover img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.2s;
	cursor: pointer;
}

.recommend .content .cover img:hover {
	transform: scale(1.1);
}

.recommend .content .box .name {
	height: 29px;
	font-size: 21px;
	margin-top: 10px;
	text-align: center;
	font-family: PingFangLight;
	font-weight: 300;
	color: #595857;
	line-height: 29px;
}

.recommend .content .box .more {
	margin-top: 17px;
}

.recommend .content .box .more span {
	width: 105px;
	height: 27px;
	font-size: 15px;
	font-family: PingFangEXTRALIGHT;
	line-height: 27px;
	padding: 3px 21px 2px 21px;
	border: 1px solid #c1c1c1;
	cursor: pointer;
	margin: 0px 208px;
}


/* 帮助 */

.help {
	/* margin-top: 160px; */
	padding-bottom: 120px;
	border-bottom: 1px solid #797979;
}

.help .title-wrap {
	text-align: center;
	height: 33px;
	line-height: 33px;
	font-size: 24px;
	font-family: PingFangLight;
	font-weight: 300;
	color: #A48D29;
}

.help .content {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* background-color: #A48D29; */
	/* align-items: center; */
}


.help .content .right,
.help .content .left {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.help .content .left{
	margin-right: 100px;
}

.help .content .left img {
	width: 28px;
	height: 52px;
	margin-right: 19px;
}

.help .content div {
	font-family: PingFangEXTRALIGHT;
	font-size: 20px;
	color: #666;
	font-weight: 100;
}

.help .content .right img{
	width: 52px;
	height: 32px;
	margin-right: 18px;
}





.con {
	width: 1300px;
	margin: 0 auto;
}
</style>
